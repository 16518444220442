import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { IRootState } from "src/store/reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh } from "@fortawesome/pro-solid-svg-icons/faTh";
import { faUserFriends } from "@fortawesome/pro-solid-svg-icons/faUserFriends";
import Auxi from "src/hoc/Auxi/Auxi";
import ContainerHeader from "src/components/UI/ContainerHeader/ContainerHeader";
import Button from "src/components/UI/Button/Button";
import { List, ListEmptyMsg } from "src/components/UI/List/";
import "./ContactsList.scss";
import {
  homeToggleDialer,
  homeChangeList,
  homeShowDetails,
  homeHideDetails
} from "src/store/actions/navigation";
import {
  IHomePageParams,
  NavigationHomeList
} from "src/store/reducers/navigation";
import SearchInput from "src/components/UI/SearchInput/SearchInput";
import { ThunkDispatch } from "redux-thunk";
import ContactItem from "src/components/ContactItem/ContactItem";
import FlipMove from "react-flip-move";
import ContactDetails from "src/components/ContactDetails/ContactDetails";
import { WindowSizeType } from "src/store/reducers/window";
import { IUserPreferences, ViewModeType } from "src/store/reducers/preferences";
import { IContact } from "src/store/reducers/contacts";
import { User } from "compass.js";
import { filterContacts, sortContacts } from "src/utils/contact";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { trackEvent, TrackCategory, TrackAction } from "src/utils/track";
import * as Infinite from "react-infinite";
import { Loader } from "../UI/Loader/Loader";
import {
  NORMAL_LIST_ITEM_HEIGHT,
  COMPACT_LIST_ITEM_HEIGHT,
  INFINITE_LIST_BREAKPOINT,
  BridgeColor
} from "src/utils/consts";
import { isPurePropsEqual } from "src/utils";
import * as shallowequal from "shallowequal";

const TRACK_CATEGORY = TrackCategory.contactsList;

class ContactsList extends React.Component<
  IContactsListProps,
  IContactsListState
> {
  public state = {
    // NOTE: searchQuery is delayed version of searchInputValue
    // which is used to prevent many filter calls
    // when user types query fast
    searchQuery: ""
  };
  private $list: HTMLDivElement | null;
  private $listWrapper: HTMLDivElement | null;
  private $activeItemWrapper: HTMLDivElement | null;

  // NOTE: keeps active contact item element on which
  // scrollIntoView method was already called to prevent
  // multiple scroll calling
  private lastScrollPos: number | null;

  shouldComponentUpdate(
    nextProps: IContactsListProps,
    nextState: IContactsListState
  ) {
    if (!isPurePropsEqual(this.props, nextProps)) {
      return true;
    }
    if (!shallowequal(this.state, nextState)) {
      return true;
    }
    // NOTE: number of contacts changed
    if (this.props.contacts.length !== nextProps.contacts.length) {
      return true;
    }
    // NOTE: order changed
    return !!this.props.contacts.find(
      (contact, idx) => contact.id !== nextProps.contacts[idx].id
    );
  }

  public componentDidUpdate() {
    this.scrollDetailsUpForMobile();
  }

  public componentDidMount() {
    this.scrollDetailsUpForMobile();
    // NOTE: to render infinite list properly we need to render
    // wrapper element first, to get exact height
    if (this.props.useInfiniteList) {
      this.forceUpdate();
    }
  }

  public render() {
    // NOTE: close details when item got removed
    if (
      this.props.openedDetails &&
      !this.props.contacts.find(
        contact => this.props.openedDetails === contact.id
      )
    ) {
      this.props.onNavigationHideDetails();
    }

    let contacts = this.props.contacts;
    if (this.state.searchQuery) {
      contacts = filterContacts(contacts, this.state.searchQuery);
    }
    const currentlyOpenedContact = contacts.find(contact =>
      this.isContactDetailsOpened(contact)
    );
    const contactsWrapCssClasses = ["contacts-list-wrap"];
    if (currentlyOpenedContact) {
      contactsWrapCssClasses.push("contacts-list-wrap--opened-details");
    }
    if (this.props.useInfiniteList) {
      contactsWrapCssClasses.push("contacts-list-wrap--infinite");
    }
    const containerHeaderCssClasses: string[] = [];
    if (!!currentlyOpenedContact) {
      containerHeaderCssClasses.push("container-header--no-mobile-border");
    }
    const containerHeaderLeftCssClasses = ["container-header-left"];
    const $navigateQueuesBtn = (
      <Button
        small={true}
        icononly={true}
        onClick={this.openQueuesList}
        onboardingStep={OnboardingStepId.navigateQueues}
        color={BridgeColor.gs300}
        tooltip={"Queues"}
        track={[TRACK_CATEGORY, TrackAction.contactsListOpenQueues]}
      >
        <FontAwesomeIcon icon={faUserFriends} />
      </Button>
    );
    return (
      <Auxi>
        <ContainerHeader
          title={"Contacts"}
          // NOTE: don't show back button in onboarding mode
          enableBackBtn={
            this.props.defaultHomeList !== NavigationHomeList.contacts &&
            !this.props.onboardingMode
          }
          backBtnClicked={this.openQueuesList}
          className={
            containerHeaderCssClasses.length
              ? containerHeaderCssClasses.join(" ")
              : undefined
          }
          backBtnTrack={[TRACK_CATEGORY, TrackAction.contactsListGoBack]}
        >
          <div className={containerHeaderLeftCssClasses.join(" ")}>
            <SearchInput
              changed={this.onSearchInputChanged}
              value={this.state.searchQuery}
              tooltip={"Search contacts"}
            />
            {this.props.addressBookContactsIsLoading ? <Loader /> : null}
          </div>
          <div className="container-header-buttons">
            {this.props.onboardingMode
              ? // NOTE: always show "go to queues" button in onboarding mode
                $navigateQueuesBtn
              : this.props.defaultHomeList === NavigationHomeList.contacts
              ? $navigateQueuesBtn
              : null}
            <Button
              tooltip={"Dial pad"}
              small={true}
              icononly={true}
              onClick={this.props.onNavigationDetailsToggleDialer}
              color={
                this.props.isDialerActive && !this.props.callIdForDtmf
                  ? BridgeColor.gs800
                  : BridgeColor.gs300
              }
              track={[TRACK_CATEGORY, TrackAction.contactsListOpenDialPad]}
            >
              <FontAwesomeIcon icon={faTh} />
            </Button>
          </div>
        </ContainerHeader>
        <div
          className={contactsWrapCssClasses.join(" ")}
          ref={el => (this.$listWrapper = el)}
        >
          <div
            className="contacts-list"
            ref={el => (this.$list = el)}
            onKeyDown={this.onKeyDown(contacts)}
            tabIndex={1}
          >
            <div className="contacts-list__inner">
              <List>
                {contacts.length ? (
                  this.$getContactsListItems(contacts)
                ) : (
                  <ListEmptyMsg>
                    {!this.props.contacts.length
                      ? "You currently have no contacts."
                      : `No contacts with "${this.state.searchQuery}" found.`}
                  </ListEmptyMsg>
                )}
              </List>
            </div>
          </div>
        </div>
      </Auxi>
    );
  }

  public scrollDetailsUpForMobile() {
    // NOTE: remove lastScrollPos every time when user resizes
    // window from mobile => desktop, to be able scroll beck when window
    // gets smaller again
    if (
      this.lastScrollPos &&
      (this.props.windowSizeType !== WindowSizeType.mobile ||
        !this.props.openedDetails)
    ) {
      this.lastScrollPos = null;
    }
    if (
      !this.props.openedDetails ||
      this.props.windowSizeType !== WindowSizeType.mobile ||
      !this.$listWrapper ||
      !this.$list
    ) {
      return;
    }
    const activeItemIdx = this.props.contacts.findIndex(
      item => item.id === this.props.openedDetails
    );
    if (activeItemIdx < 0) {
      return;
    }
    // NOTE: for infinite we're not sure that $activeItemWrapper rendered
    // so scrolling to it based on calculated position
    const scrollPosition =
        activeItemIdx * this.getItemHeight() + 15 /* list padding */;
    if (this.lastScrollPos === scrollPosition) {
      return;
    }
    if (this.$activeItemWrapper) {
      this.$activeItemWrapper.scrollIntoView({
        // NOTE: remove scroll animation if
        // details active and pin was toggled or list got reordered
        behavior:
          this.lastScrollPos && this.lastScrollPos !== scrollPosition
            ? "auto"
            : "smooth",
        block: "start"
      });
      this.lastScrollPos = scrollPosition;
    } else if (this.props.useInfiniteList) {
      const $infiniteList = this.$listWrapper.querySelector(
        ".contacts-list-infinite"
      );
      if ($infiniteList) {
        $infiniteList.scrollTo(0, scrollPosition);
        this.lastScrollPos = scrollPosition;
      }
    }
  }

  private $getContactsListItems(contacts: IContact[]): React.ReactNode {
    const $items = contacts.map(contact => {
      const isItemActive = this.props.openedDetails === contact.id;
      const wrapperCssClasses = ["contacts-list__item-wrapper"];
      if (isItemActive) {
        wrapperCssClasses.push("contacts-list__item-wrapper--active");
      }
      const isActive = this.isContactDetailsOpened(contact);
      return (
        <div key={contact.id} className={wrapperCssClasses.join(" ")}>
          <div
            ref={
              isItemActive
                ? el => {
                    this.$activeItemWrapper = el;
                  }
                : null
            }
          >
            <ContactItem
              id={contact.id}
              isActive={isActive}
              disableSkeleton={isActive}
              key={contact.id}
              onClick={this.onContactClick}
              showPinBtn={true}
              onDetailsToggle={this.toggleDetails}
            />
          </div>
          {isItemActive ? (
            <div className="contacts-list__details-wrap br-screen-small">
              <ContactDetails id={contact.id} hideHeader={true} inline={true} />
            </div>
          ) : null}
        </div>
      );
    });
    if (!this.props.useInfiniteList) {
      return (
        <FlipMove
          enterAnimation="none"
          leaveAnimation="none"
          disableAllAnimations={!!this.props.openedDetails}
        >
          {$items}
        </FlipMove>
      );
    }
    if (!this.$list) {
      return null;
    }
    return (
      <Infinite
        containerHeight={this.$list.clientHeight}
        elementHeight={this.getItemHeight()}
        preloadAdditionalHeight={Infinite.containerHeightScaleFactor(2)}
        className="contacts-list-infinite"
      >
        {$items}
      </Infinite>
    );
  }

  private onContactClick = (id: IContact["id"]) => {
    if (this.props.onboardingMode) {
      return;
    }
    if (this.props.windowSizeType === WindowSizeType.mobile) {
      this.toggleDetails(id);
      return;
    }
    this.props.onNavigationShowDetails(id);
  };

  private getItemHeight = () =>
    this.props.viewMode === ViewModeType.comfortable
      ? NORMAL_LIST_ITEM_HEIGHT
      : COMPACT_LIST_ITEM_HEIGHT;

  private onKeyDown = (contacts: IContact[]) => (e: React.KeyboardEvent) => {
    if (
      this.props.windowSizeType === WindowSizeType.mobile ||
      !["ArrowUp", "ArrowDown"].includes(e.key)
    ) {
      return;
    }
    e.preventDefault();
    const currentActiveContactIdx = contacts.findIndex(contact =>
      this.isContactDetailsOpened(contact)
    );
    let nextActiveContactIdx: undefined | number;
    let $nextElement: null | Node = null;
    if (e.key === "ArrowUp") {
      // up arrow
      nextActiveContactIdx = Math.max(0, currentActiveContactIdx - 1);
      if (this.$activeItemWrapper && this.$activeItemWrapper.parentElement) {
        $nextElement = this.$activeItemWrapper.parentElement.previousSibling;
      }
    } else if (e.key === "ArrowDown") {
      // down arrow
      nextActiveContactIdx = Math.min(
        contacts.length - 1,
        currentActiveContactIdx + 1
      );
      if (this.$activeItemWrapper && this.$activeItemWrapper.parentElement) {
        $nextElement = this.$activeItemWrapper.parentElement.nextSibling;
      }
    }
    if (
      nextActiveContactIdx === undefined ||
      currentActiveContactIdx === nextActiveContactIdx ||
      !contacts[nextActiveContactIdx]
    ) {
      return;
    }
    this.props.onNavigationShowDetails(contacts[nextActiveContactIdx].id);
    if ($nextElement) {
      ($nextElement as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      });
    }
  };

  private toggleDetails = (id: IContact["id"]) => {
    if (this.props.openedDetails) {
      this.closeDetails();
    } else {
      this.props.onNavigationShowDetails(id);
      trackEvent(TRACK_CATEGORY, TrackAction.contactsListOpenDetails);
    }
  };

  private closeDetails = () => {
    this.props.onNavigationHideDetails();
    // NOTE: hack, due to browser not updating scroll
    // props when items from bottom of the list got closed
    setTimeout(() => {
      const $list = this.$list as HTMLDivElement;
      $list.scrollTop = $list.scrollTop - 1;
      $list.scrollTop = $list.scrollTop + 1;
    }, 100);
  };

  private onSearchInputChanged = (value: string) => {
    this.setState({
      searchQuery: value
    });
  };

  private openQueuesList = () => {
    this.props.onNavigationChangeList(NavigationHomeList.queues);
  };

  private isContactDetailsOpened(contact: IContact): boolean {
    return contact.id === this.props.openedDetails;
  }
}

interface IPropsFromState {
  contacts: IContact[];
  windowSizeType: WindowSizeType;
  addressBookContactsIsLoading: boolean;
  onboardingMode: boolean;
  useInfiniteList: boolean;
  openedDetails?: string;
  isDialerActive: boolean;
  defaultHomeList: IUserPreferences["defaultHomeList"];
  viewMode: IUserPreferences["viewMode"];
  callIdForDtmf?: string;
  windowHeight?: number;
}

interface IPropsFromDispatch {
  onNavigationDetailsToggleDialer: () => void;
  onNavigationChangeList: (list: NavigationHomeList) => void;
  onNavigationShowDetails: (id: string) => void;
  onNavigationHideDetails: () => void;
}

interface IContactsListProps extends IPropsFromState, IPropsFromDispatch {}

interface IContactsListState {
  searchQuery: string;
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  const navigationParams = state.navigation.params as IHomePageParams;
  // NOTE: remove self from contacts list
  const contactsMap = {
    ...state.contacts.compassItems,
    ...state.contacts.addressBookItems
  };
  delete contactsMap[(state.auth.user as User).id];
  const contacts = sortContacts(Object.values(contactsMap));
  const useInfiniteList = contacts.length > INFINITE_LIST_BREAKPOINT;
  return {
    contacts,
    windowSizeType: state.window.sizeType,
    addressBookContactsIsLoading: state.contacts.addressBookContactsIsLoading,
    onboardingMode: state.auth.onboardingMode,
    openedDetails:
      navigationParams.detailsOpened && navigationParams.detailsId
        ? navigationParams.detailsId.toString()
        : undefined,
    isDialerActive: navigationParams.dialerActive,
    defaultHomeList: state.preferences.user.defaultHomeList,
    viewMode: state.preferences.user.viewMode,
    callIdForDtmf: (state.navigation.params as IHomePageParams).callIdForDtmf,
    useInfiniteList,
    // NOTE: it used only to force infinite list height update
    // on window resize
    windowHeight: useInfiniteList ? state.window.bounds.height : undefined
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onNavigationDetailsToggleDialer: () => dispatch(homeToggleDialer()),
    onNavigationChangeList: (list: NavigationHomeList) =>
      dispatch(homeChangeList(list)),
    onNavigationShowDetails: (id: string) => dispatch(homeShowDetails(id)),
    onNavigationHideDetails: () => dispatch(homeHideDetails())
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(ContactsList);
