import { IRootState } from "./reducers/index";
import { createStore, applyMiddleware, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./reducers";
import { createStateSyncMiddleware } from "redux-state-sync";
import * as actionTypes from "./actions/actionTypes";
import thunk from "redux-thunk";

const middleware = [
  thunk,
  createStateSyncMiddleware({
    whitelist: [
      actionTypes.CALLS_START_TRANSFERRING,
      actionTypes.CALLS_DISMISS_TRANSFERRING,
      actionTypes.CALLS_REGISTER_ATTENDED_TRANSFER,
      actionTypes.CONTACTS_COMPASS_UPDATE_PINNED,
      actionTypes.QUEUES_UPDATE_PINNED,
      actionTypes.QUEUES_SET_GLOBAL_PAUSE,
      actionTypes.PREFERENCES_UPDATE_USER,
      actionTypes.AUTH_CLEAR_USER_DATA
    ]
  })
];

export const store: Store<IRootState> = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
