import * as actionTypes from "src/store/actions/actionTypes";

export enum NavigationPage {
  home = "home",
  login = "login",
  contactsOverview = "contactsOverview"
}

export enum NavigationHomeList {
  queues = "queues",
  contacts = "contacts"
}

export interface IHomePageParams {
  list: NavigationHomeList;
  dialerActive: boolean;
  callIdForDtmf?: string;
  detailsOpened: boolean;
  detailsId?: string;
}

export interface INavigationState {
  readonly page?: NavigationPage;
  readonly params?: IHomePageParams;
}

// TODO: move initial definition out
const initialState: INavigationState = {
  page: undefined,
  params: undefined
};

const reducer = (
  state: INavigationState = initialState,
  action: any
): INavigationState => {
  switch (action.type) {
    case actionTypes.NAVIGATION_SET:
      return {
        ...state,
        page: action.payload.page,
        params: action.payload.params || {}
      };

    case actionTypes.NAVIGATION_UPDATE_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload.params
        }
      };

    case actionTypes.NAVIGATION_REPLACE_PARAMS:
      return {
        ...state,
        page: action.payload.page,
        params: action.payload.params || {}
      };

    case actionTypes.NAVIGATION_HOME_TOGGLE_DIALER:
      if (state.page !== "home") {
        throw new Error("This functions can be used on home page only");
      }
      const homePageParams = state.params as IHomePageParams;
      let dialerActive = !homePageParams.dialerActive;
      if (homePageParams.callIdForDtmf) {
        // from dtmf state, always go to normal dialer
        dialerActive = true;
      }
      return {
        ...state,
        params: {
          ...homePageParams,
          dialerActive,
          callIdForDtmf: undefined,
          detailsOpened: false,
          detailsId: undefined
        }
      };

    case actionTypes.NAVIGATION_HOME_SHOW_DETAILS:
      if (state.page !== "home") {
        throw new Error("This functions can be used on home page only");
      }
      return {
        ...state,
        params: {
          ...(state.params as IHomePageParams),
          dialerActive: false,
          detailsOpened: true,
          detailsId: action.payload
        }
      };

    case actionTypes.NAVIGATION_HOME_HIDE_DETAILS:
      if (state.page !== "home") {
        throw new Error("This functions can be used on home page only");
      }
      return {
        ...state,
        params: {
          ...(state.params as IHomePageParams),
          dialerActive: false,
          detailsOpened: false,
          detailsId: undefined
        }
      };

    case actionTypes.NAVIGATION_HOME_CHANGE_LIST:
      if (state.page !== "home") {
        throw new Error("This functions can be used on home page only");
      }
      return {
        ...state,
        params: {
          ...(state.params as IHomePageParams),
          list: action.payload,
          dialerActive: false,
          detailsOpened: false,
          detailsId: undefined
        }
      };

    default:
      return state;
  }
};

export default reducer;
