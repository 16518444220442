import * as actionTypes from "src/store/actions/actionTypes";
import { NavigationHomeList } from "./navigation";

export interface IPreferencesState {
  user: IUserPreferences;
}

export enum ViewModeType {
  comfortable = "comfortable",
  compact = "compact"
}

export interface IUserPreferences {
  readonly defaultHomeList: NavigationHomeList;
  readonly viewMode: ViewModeType;
}

const initialUserPreferences: IUserPreferences = {
  defaultHomeList: NavigationHomeList.queues,
  viewMode: ViewModeType.comfortable
};

const initialState: IPreferencesState = {
  /* NOTE: user preferences should always keeps initial 
     preferences if user not logged in
     or didn't change default preferences yet,
     to prevent weird use cases around the app */
  user: initialUserPreferences
};

const reducer = (
  state: IPreferencesState = initialState,
  action: any
): IPreferencesState => {
  switch (action.type) {
    case actionTypes.PREFERENCES_UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    case actionTypes.PREFERENCES_CLEAR_USER:
      return {
        ...state,
        user: initialUserPreferences
      };
    default:
      return state;
  }
};

export default reducer;
