import * as actionTypes from "./actionTypes";
import {
  NavigationPage,
  NavigationHomeList
} from "src/store/reducers/navigation";

export const navigationSet = (
  page: NavigationPage,
  params?: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.NAVIGATION_SET,
    payload: {
      page,
      params
    }
  };
};

export const navigationUpdateParams = (
  params: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.NAVIGATION_UPDATE_PARAMS,
    payload: {
      params
    }
  };
};

export const navigationReplaceParams = (
  params: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.NAVIGATION_REPLACE_PARAMS,
    payload: {
      params
    }
  };
};

export const homeToggleDialer = (): { type: string } => {
  return {
    type: actionTypes.NAVIGATION_HOME_TOGGLE_DIALER
  };
};

export const homeShowDetails = (id: string): { type: string; payload: any } => {
  return {
    type: actionTypes.NAVIGATION_HOME_SHOW_DETAILS,
    payload: id
  };
};

export const homeHideDetails = (): { type: string } => {
  return {
    type: actionTypes.NAVIGATION_HOME_HIDE_DETAILS
  };
};

export const homeChangeList = (
  list: NavigationHomeList
): { type: string; payload: any } => {
  return {
    type: actionTypes.NAVIGATION_HOME_CHANGE_LIST,
    payload: list
  };
};
