import { reactIsInDevMode } from "./index";
import { store } from "src/store";
import { notificationShow } from "src/store/actions";

export enum ErrorType {
  api = "api",
  noPhone = "noPhone",
  noOutboundNumber = "noOutboundNumber",
  runtime = "runtime",
  addressBook = "addressBook",
  loginBasedomConnection = "loginBasedomConnection"
}

export interface IError {
  type: ErrorType;
  error?: any;
}

export const handleError = (error?: IError) => {
  if (reactIsInDevMode()) {
    console.error(error);
  }
  // NOTE: don't show errors during onboarding
  if (store.getState().auth.onboardingMode) {
    return;
  }
  let message =
    "Something has gone wrong, please try again later or contact support";
  if (error) {
    switch (error.type) {
      case ErrorType.addressBook:
        message = "There was an error while attempting access to address book.";
        break;
      case ErrorType.loginBasedomConnection:
        message = "Can't connect to the server given in credentials.";
        break;
      case ErrorType.noPhone:
        message = "You need to log in to a phone to make and receive calls.";
        break;
      case ErrorType.noOutboundNumber:
        message =
          "Your account doesn't have an outbound number. Contact your administrator to configure it in Compass.";
        break;
      case ErrorType.api:
        if (!store.getState().window.online) {
          message = "There is no connection. Try reconnecting.";
        } else if (
          error.error &&
          error.error.responseJSON &&
          error.error.responseJSON.message
        ) {
          message = error.error.responseJSON.message;
        }
        break;
    }
  }
  store.dispatch(
    notificationShow({
      message,
      autoDismiss: 5000,
      level: "danger"
    })
  );
};

/**
 * Automatically converts API error response to IError
 * @param p Promise
 */
export const wrapApiError = <T>(p: Promise<T>) => {
  return p.catch(error => {
    throw {
      type: ErrorType.api,
      error
    };
  });
};

/**
 * Automatically converts API error response to IError
 * @param p Promise
 */
export const wrapAddressBookError = <T>(p: Promise<T>) => {
  return p.catch(error => {
    throw {
      type: ErrorType.addressBook,
      error
    };
  });
};

/**
 * Convert runtime error to IError
 * @param error Exception
 */
export const createRuntimeError = (error?: any): IError => {
  return {
    type: ErrorType.runtime,
    error
  };
};

/**
 * Generate error when user doesn't have phone
 */
export const checkPhone = () => {
  const userStatus = store.getState().auth.userStatus;
  if (!userStatus || !userStatus.phone) {
    throw {
      type: ErrorType.noPhone
    };
  }
};
