import * as React from "react";
import "./SearchInput.scss";
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "src/components/UI/Button/Button";
import { BridgeColor } from "src/utils/consts";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

class SearchInput extends React.Component<
  ISearchInputProps,
  ISearchInputState
> {
  private $wrapperRef: HTMLDivElement | null;
  private $inputRef: HTMLInputElement | null;
  private debounceTimeout: NodeJS.Timer | null;

  constructor(props: ISearchInputProps) {
    super(props);
    this.state = {
      opened: !!props.opened
    };
  }

  componentDidUpdate(prevProps: ISearchInputProps) {
    if (
      this.props.value !== prevProps.value &&
      this.props.value !== this.state.value
    ) {
      this.setState({
        value: this.props.value
      });
    }
  }
  componentDidMount() {
    document.addEventListener("click", this.onDocumentClick);
    document.addEventListener("keyup", this.onDocumentKeyUp);
    if (this.props.autoFocus) {
      this.focusInput();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onDocumentClick);
    document.removeEventListener("keyup", this.onDocumentKeyUp);
  }

  render() {
    const cssClasses = ["search-input"];
    const isOpened = this.state.opened || this.props.alwaysOpened;
    if (isOpened) {
      cssClasses.push("search-input--opened");
    }
    if (this.props.alwaysOpened) {
      cssClasses.push("search-input--always-opened");
    }
    if (this.props.dark) {
      cssClasses.push("search-input--dark");
    }
    cssClasses.push(`search-input--expand-${this.props.expand || "default"}`);

    return (
      <div className={cssClasses.join(" ")} ref={this.setWrapperRef}>
        <div className="search-input__toggle-btn">
          <Button
            small={true}
            onClick={
              this.props.alwaysOpened || (isOpened && this.props.value)
                ? undefined
                : this.toggleInput
            }
            color={
              isOpened
                ? this.props.dark
                  ? BridgeColor.gs0
                  : BridgeColor.gs800
                : this.props.dark
                ? BridgeColor.gs800
                : BridgeColor.gs300
            }
            icononly={true}
            nohover={isOpened}
            tooltip={this.props.tooltip || ""}
            fill={isOpened ? "clear" : "solid"}
          >
            <FontAwesomeIcon icon={this.getButtonIcon()} />
          </Button>
        </div>
        <div className="search-input__input-wrap">
          <input
            className="search-input__input"
            value={this.state.value}
            placeholder="Search"
            autoFocus={this.props.autoFocus}
            ref={ref => (this.$inputRef = ref)}
            onChange={this.onInputChanged}
          />
          {this.state.value || this.props.closeOnClear ? (
            <Button
              small={true}
              icononly={true}
              onClick={this.clearInput}
              color={this.props.dark ? BridgeColor.gs0 : BridgeColor.gs800}
              nohover={true}
              fill={"clear"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          ) : null}
        </div>
      </div>
    );
  }

  toggleInput = () => {
    if (this.props.alwaysOpened) {
      return;
    }
    this.setState(
      {
        opened: !this.state.opened
      },
      () => {
        if (this.state.opened) {
          if (this.props.onOpen) {
            this.props.onOpen();
          }
          this.focusInput();
        } else {
          if (this.props.onClose) {
            this.props.onClose();
          }
        }
      }
    );
  };

  private getButtonIcon() {
    return this.state.opened
      ? this.props.openedIcon || faSearch
      : this.props.closedIcon || faSearch;
  }

  private setWrapperRef = (node: HTMLDivElement) => {
    this.$wrapperRef = node;
  };

  private onDocumentKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Escape" && this.state.opened && !this.props.alwaysOpened) {
      this.clearInput();
      if (this.props.onClose) {
        this.props.onClose();
      }
      this.setState({
        opened: false
      });
    }
  };

  private onDocumentClick = (e: MouseEvent) => {
    if (
      !this.props.disableOutsideClick &&
      !this.props.value &&
      this.state.opened &&
      this.$wrapperRef &&
      !this.$wrapperRef.contains(e.target as Node)
    ) {
      this.toggleInput();
    }
  };

  private focusInput = () => {
    if (this.$inputRef) {
      this.$inputRef.focus();
    }
  };

  private clearInput = () => {
    this.triggerChanged("");
    if (this.props.closeOnClear) {
      this.setState({ value: "", opened: false });
      if (this.props.onClose) {
        this.props.onClose();
      }
    } else {
      this.setState({ value: "" });
    }
  };

  private onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.triggerChanged(e.target.value);
    this.setState({ value: e.target.value });
  };

  private triggerChanged = (value: string) => {
    if (!this.props.changed) {
      return;
    }
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      this.debounceTimeout = null;
      this.props.changed(value);
    }, 300);
  };
}

interface ISearchInputProps {
  value?: string;
  changed: (query: string) => void;
  className?: string | null;
  tooltip?: string;
  alwaysOpened?: boolean;
  autoFocus?: boolean;
  opened?: boolean;
  disableOutsideClick?: boolean;
  closeOnClear?: boolean;
  expand?: "full" | "default";

  onOpen?: () => void;
  onClose?: () => void;
  closedIcon?: IconDefinition;
  openedIcon?: IconDefinition;
  dark?: boolean;
}

interface ISearchInputState {
  opened: boolean;
  value?: string;
}

export default SearchInput;
