import { reactIsInDevMode } from "./../../utils/index";
import { LoginPhoneModalType } from "./../../components/LoginPhoneModal/LoginPhoneModal";
import { compassDebouncePipe } from "./../../utils/compassDataMiddleware";
import { ErrorType } from "./../../utils/errorHandler";
import { NavigationHomeList } from "src/store/reducers/navigation";
import {
  UserPermission,
  ICompassPhone,
  IUserCachedInfo,
  ICompassUserStatus,
  OnboardingType,
  IPhoneData,
  ICompassPhoneStatus
} from "./../reducers/auth";
import { IRootState } from "./../reducers/index";
import { userStorage } from "src/utils/userStorage";
import { setupCalls } from "./calls";
import { setupContacts, addressBookSetLoaded } from "./contacts";
import { setupQueues } from "./queues";
import { User, Connection, RestApi, Model, ReceiveCalls } from "compass.js";
import {
  IAuthState,
  ICompassCompany,
  PhoneCapability,
  UserFeature
} from "src/store/reducers/auth";
import * as actionTypes from "./actionTypes";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "..";
import { unloadUserPreferences, loadUserPreferences } from "./preferences";
import {
  notificationShow,
  notificationDismiss,
  notificationsDismissAll
} from "./notifications";
import * as clone from "clone";
import { OnboardingController } from "src/utils/OnboardingController";
import { OnboardingStep } from "src/utils/OnboardingStep";
import { compassDataMiddleware } from "src/utils/compassDataMiddleware";
import { tickIntervalPause, tickIntervalUnpause } from "./tickInterval";
import { homeChangeList, navigationSet } from "./navigation";
import { setUserId } from "src/utils/track";
import { appUsage } from "src/utils/appUsage";
import { NavigationPage } from "../reducers/navigation";
import { wrapApiError, handleError } from "src/utils/errorHandler";
import { compassObjUrlToID } from "src/utils";
import * as aes from "crypto-js/aes";
import * as encUtf8 from "crypto-js/enc-utf8";
import {
  getLoginEnvironmentFromUrl
} from "../../utils/url";

interface IConnectInfo {
  server: string;
  jid: string;
}

const authStorage = localStorage;
const AUTH_STATE_KEY = "bridge:auth:state";

const CACHED_USER_INFO_KEY = "auth:cached-user-info";
const DEFAULT_SERVER = "apollo.compass-env.com";
const RECENT_PHONES_KEY = "auth:recent-phones";
const STORE_SECRET_PHRASE = "bridge:phrase";

const saveUserCachedInfo = (userInfo: IUserCachedInfo) => {
  authStorage.setItem(CACHED_USER_INFO_KEY, JSON.stringify(userInfo));
};

const getUserCachedInfo = (): IUserCachedInfo | null => {
  const userInfo = authStorage.getItem(CACHED_USER_INFO_KEY);
  if (!userInfo) {
    return null;
  }
  try {
    return JSON.parse(userInfo);
  } catch (error) {
    // NOTE: bad json stored, clearing
    clearUserCachedInfo();
    return null;
  }
};

const clearUserCachedInfo = () => {
  authStorage.removeItem(CACHED_USER_INFO_KEY);
};

const clearLoginCredentials = () => {
  authStorage.removeItem(AUTH_STATE_KEY);
};

const updateUserCompanyAction = (company: ICompassCompany) => ({
  type: actionTypes.AUTH_UPDATE_USER_COMPANY,
  payload: company
});

const updateUserCompanyPermissionsAction = (permission: UserPermission) => ({
  type: actionTypes.AUTH_UPDATE_USER_COMPANY_PERMISSION,
  payload: permission
});

const updateUserFeaturesAction = (features: UserFeature[]) => ({
  type: actionTypes.AUTH_UPDATE_USER_FEATURES,
  payload: features
});

const setUserPhoneDataAction = (phoneData?: IPhoneData) => ({
  type: actionTypes.AUTH_SET_PHONE_DATA,
  payload: phoneData
});

const setUserRecentPhones = (recentPhones: number[]) => ({
  type: actionTypes.AUTH_SET_RECENT_PHONES,
  payload: recentPhones
});

const setUserStatusAction = (userStatus?: ICompassUserStatus) => ({
  type: actionTypes.AUTH_SET_USER_STATUS,
  payload: userStatus
});

const setApiVersion = (apiVersion: number) => ({
  type: actionTypes.AUTH_SET_API_VERSION,
  payload: apiVersion
});

const updateUserStatus = (): ThunkAction<
  Promise<
    { userStatus: ICompassUserStatus; phoneData: IPhoneData } | undefined
  >,
  IRootState,
  void,
  AnyAction
> => {
  return async (dispatch, getState, extraParams) => {
    const connection = getState().auth.connection;
    const user = getState().auth.user;
    if (!connection || !user) {
      dispatch(setUserPhoneDataAction());
      return;
    }
    const userStatus = await wrapApiError<ICompassUserStatus>(
      connection.rest.get(
        `${connection.rest.getUrlForObject(
          "user",
          parseInt(user.id, 10)
        )}/status`
      )
    );
    dispatch(setUserStatusAction(userStatus));
    let recentPhones: number[] =
      (await userStorage.getItem<number[]>(RECENT_PHONES_KEY)) || [];
    if (!userStatus || !userStatus.phone) {
      dispatch(setUserPhoneDataAction());
      dispatch(setUserRecentPhones(recentPhones));
      return;
    }
    const phone = await wrapApiError<ICompassPhone>(
      connection.rest.get(userStatus.phone)
    );
    const phoneCapabilities = await wrapApiError<
      Array<{ value: PhoneCapability }>
    >(connection.rest.get(`${userStatus.phone}/capabilities`));
    const phoneId = compassObjUrlToID(userStatus.phone);
    let phoneStatus: ICompassPhoneStatus | undefined;
    try {
      phoneStatus = await wrapApiError<ICompassPhoneStatus>(
        connection.rest.get(`${userStatus.phone}/phoneStatus`)
      );
    } catch (error) {
      // NOTE: don't throw error if user
      // doesn't have read rights
      if (
        !error ||
        error.type !== ErrorType.api ||
        (!error.error && error.error.status !== 403)
      ) {
        throw error;
      }
    }
    const phoneData: IPhoneData = {
      id: phoneId,
      name: phone.name,
      capabilities: phoneCapabilities.map(item => item.value),
      status: phoneStatus
    };
    recentPhones = [
      phoneId,
      ...recentPhones.filter(item => item !== phoneId)
    ].slice(0, 3);
    await userStorage.setItem(RECENT_PHONES_KEY, recentPhones);
    dispatch(setUserRecentPhones(recentPhones));
    dispatch(setUserPhoneDataAction(phoneData));
    return { phoneData, userStatus };
  };
};

const getConnectInfo = async (
  fullUsername: string,
  password: string
): Promise<IConnectInfo> => {
  // for development: username can be username?mystack.compass.com
  // to use a non-apollo stack
  const [username, connectDomain] = fullUsername.split("?");
  // username can be regular (username) or with company domain (username@companydomain.com)
  const [localuser] = username.split("@");

  let stackBasedom: string;

  const environmentFromUrl = getLoginEnvironmentFromUrl();
  if (connectDomain) {
    // user specifically entered an environment
    stackBasedom = connectDomain;
  } else if (environmentFromUrl) {
    // environment was specified in the url
    stackBasedom = environmentFromUrl;
  } else {
    // NOTE: If user did not specify a domain, assume it's on the Apollo stack.
    stackBasedom = DEFAULT_SERVER;
  }

  // Resolve the correct whitelabel basedom to connect to
  let company: ICompassCompany;
  try {
    const restApi = new RestApi(stackBasedom, username, password);
    company = await wrapApiError(restApi.get("company"));
  } catch (error) {
    if (
      connectDomain &&
      error &&
      error.type === ErrorType.api &&
      error.error &&
      error.error.readyState === 0
    ) {
      error.type = ErrorType.loginBasedomConnection;
    }
    throw error;
  }
  // retrieve whitelabel domain by splitting the company's sipDomain
  const whitelabelBasedom = company.sipDomain
    .split(".")
    .slice(1)
    .join(".");

  return {
    server: whitelabelBasedom,
    // for regular users: username@uc.whitelabelreseller.com
    // for company-domain users: username@companydomain.com
    jid: `${localuser}@${company.xmppDomain}`
  };
};

const updateUserFeatures = (): ThunkAction<
  Promise<any>,
  IRootState,
  void,
  AnyAction
> => {
  return (dispatch, getState, extraParams) => {
    const connection = getState().auth.connection;
    const user = getState().auth.user;
    return new Promise((resolve, reject) => {
      if (!connection || !user) {
        return reject("Connection or user not exist in store");
      }
      wrapApiError(
        connection.rest.get(
          `${connection.rest.getUrlForObject(
            "user",
            parseInt(user.id, 10)
          )}/features`
        )
      ).then((features: Array<{ id: UserFeature; name: string }>) => {
        dispatch(updateUserFeaturesAction(features.map(item => item.id)));
        resolve();
      }, reject);
    });
  };
};

const updateUserPermissions = (
  company: ICompassCompany
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const connection = getState().auth.connection;
    const user = getState().auth.user;
    return new Promise((resolve, reject) => {
      if (!connection || !user) {
        return reject("Connection not exist in store");
      }
      connection.rest
        .get(
          `${connection.rest.getUrlForObject(
            "user",
            parseInt(user.id, 10)
          )}/permission?targetEntity=${company.self}`
        )
        .then((response: { value: UserPermission }) => {
          dispatch(updateUserCompanyPermissionsAction(response.value));
          resolve();
        }, reject);
    });
  };
};

const updateUserInfo = (user: User) => {
  return {
    type: actionTypes.AUTH_UPDATE_USER_INFO,
    payload: clone(user, undefined, 2)
  };
};

const updateConnection = (connection: Connection) => {
  return {
    type: actionTypes.AUTH_UPDATE_CONNECTION,
    payload: connection
  };
};

const saveLoginCredentials = (username: string, password: string) => {
  authStorage.setItem(
    AUTH_STATE_KEY,
    aes
      .encrypt(JSON.stringify({ username, password }), STORE_SECRET_PHRASE)
      .toString()
  );
};

const getLoginCredentials = (): {
  username: string | null;
  password: string | null;
} => {

  // If the username/password is given in the URL, so a 'single-sign-on'-like
  // direct login.
  const q = new URLSearchParams(location.search);
  if (q.get('username') && q.get('password')) {
    saveLoginCredentials(q.get('username')!!, q.get('password')!!);
    // redirect, so the url becomes clean
    window.location.href = '/';
  }

  try {
    // TODO: remove this functionality when all users will use v1.4.0+
    const oldUsername = authStorage.getItem("auth:username");
    const oldPassword = authStorage.getItem("auth:password");
    if (oldUsername || oldPassword) {
      authStorage.removeItem("auth:username");
      authStorage.removeItem("auth:password");
      if (oldUsername && oldPassword) {
        saveLoginCredentials(oldUsername, oldPassword);
      }
    }
    //
    const credentials: { username: string; password: string } = JSON.parse(
      aes
        .decrypt(authStorage.getItem(AUTH_STATE_KEY) || "", STORE_SECRET_PHRASE)
        .toString(encUtf8)
    );
    if (!credentials.username || !credentials.password) {
      throw new Error();
    }
    return credentials;
  } catch {
    return {
      username: null,
      password: null
    };
  }
};

const loginStarted = (): { type: string } => {
  return {
    type: actionTypes.AUTH_LOGIN_STARTED
  };
};

const loginFinished = (
  isAuthenticated: boolean
): { type: string; payload: boolean } => {
  return {
    type: actionTypes.AUTH_LOGIN_FINISHED,
    payload: isAuthenticated
  };
};

const clearUserData = () => {
  return {
    type: actionTypes.AUTH_CLEAR_USER_DATA
  };
};

const initializationStarted = (): { type: string } => {
  return {
    type: actionTypes.AUTH_INITIALIZATION_STARTED
  };
};

const startOnboarding = (
  controller: OnboardingController,
  type: OnboardingType
): {
  type: string;
  payload: {
    controller: OnboardingController;
    type: OnboardingType;
  };
} => {
  return {
    type: actionTypes.AUTH_START_ONBOARDING,
    payload: {
      controller,
      type
    }
  };
};

const stopOnboarding = (): { type: string } => {
  return {
    type: actionTypes.AUTH_STOP_ONBOARDING
  };
};

export const loginUserOnPhone = (
  userId: User["id"],
  phoneId: number
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return (dispatch, getState, extraParams) => {
    const connection = getState().auth.connection;
    if (!connection) {
      throw new Error("Connection not exist in store");
    }
    return wrapApiError(
      connection.rest.post(
        `${connection.rest.getUrlForObject(
          "user",
          parseInt(userId, 10)
        )}/logon`,
        {
          phone: connection.rest.getUrlForObject("phone", phoneId)
        }
      )
    );
  };
};

export const logoutUserFromPhone = (
  userId: User["id"]
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return (dispatch, getState, extraParams) => {
    const connection = getState().auth.connection;
    if (!connection) {
      throw new Error("Connection not exist in store");
    }
    return wrapApiError(
      connection.rest.post(
        `${connection.rest.getUrlForObject(
          "user",
          parseInt(userId, 10)
        )}/logoff`,
        {}
      )
    );
  };
};

const updateUserCachedInfo = (
  userCachedInfo: IUserCachedInfo
): { type: string; payload: IUserCachedInfo } => {
  return {
    type: actionTypes.AUTH_UPDATE_USER_CACHED_INFO,
    payload: userCachedInfo
  };
};

export const beginOnboarding = (
  type: OnboardingType
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(tickIntervalPause());
      let user: User | undefined;
      if (type === OnboardingType.guest) {
        // NOTE: setup user for guest onboarding
        const model = new Model();
        user = new User("9999", null as any, model);
        user.jid = "demo@iperity.com";
        user.name = "Demo User";
        user.status = {
          receiveCalls: ReceiveCalls.all,
          displayStatus: "",
          wrapupState: null
        };
        model.users[user.id] = user;

        userStorage.init(user.jid);
        dispatch(updateUserInfo(user));
        dispatch(loginFinished(true));

        setupUser(dispatch);
        setupCalls(dispatch);
        setupQueues(dispatch);
        setupContacts(dispatch);
      } else {
        user = getState().auth.user as User;
      }

      const onboardingController = new OnboardingController(
        user,
        type,
        type === OnboardingType.default
          ? {
              userHasPremium: getState().auth.features.includes(
                UserFeature.callcontrol
              )
            }
          : {}
      );
      dispatch(startOnboarding(onboardingController, type));

      dispatch(
        navigationSet(NavigationPage.home, {
          list: NavigationHomeList.contacts,
          dialerActive: false,
          detailsOpened: false
        })
      );

      onboardingController.start();
      compassDataMiddleware.setSource(
        onboardingController.getConnection(),
        user.jid
      );
      setTimeout(() => {
        dispatch(tickIntervalUnpause());
        resolve();
      }, 1000);
    });
  };
};

export const endOnboarding = (): ThunkAction<
  Promise<any>,
  IRootState,
  void,
  AnyAction
> => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch(tickIntervalPause());
      const onboardingType = getState().auth.onboardingType;
      if (onboardingType === OnboardingType.guest) {
        return dispatch(logout(true));
      }

      dispatch(homeChangeList(getState().preferences.user.defaultHomeList));
      const user = store.getState().auth.backup.user;
      const connection = store.getState().auth.backup.connection;
      if (!user || !connection) {
        return reject();
      }

      dispatch(stopOnboarding());
      dispatch(addressBookSetLoaded(false));
      compassDataMiddleware.setSource(connection, user.jid);
      try {
        // NOTE: show login phone modal if user not logged in to any phone
        if (!getState().auth.phone) {
          dispatch(showLoginPhoneModal(LoginPhoneModalType.login));
        }
      } catch (error) {
        // NOTE: fail silently, due to it's background process
        if (reactIsInDevMode()) {
          console.error(error);
        }
      }
      setTimeout(() => {
        dispatch(tickIntervalUnpause());
        resolve();
      }, 1000);
    });
  };
};

export const onboardingNext = (): ThunkAction<
  Promise<any>,
  IRootState,
  void,
  AnyAction
> => {
  return async (dispatch, getState) => {
    const onboardingController = store.getState().auth.onboardingController;
    const onboardingStep = getState().auth.onboardingStep;
    if (!onboardingController || !onboardingStep) {
      console.warn(
        "WARNING: trying to use onboarding function outside onboarding mode"
      );
      return;
    }
    if (onboardingStep.isLastStep) {
      return dispatch(endOnboarding());
    }
    return dispatch(onboardingSetStep(onboardingController.getNextStep()));
  };
};

export const onboardingPrev = (): ThunkAction<
  Promise<any>,
  IRootState,
  void,
  AnyAction
> => {
  return async (dispatch, getState) => {
    const onboardingController = store.getState().auth.onboardingController;
    const onboardingStep = getState().auth.onboardingStep;
    if (!onboardingController || !onboardingStep) {
      console.warn(
        "WARNING: trying to use onboarding function outside onboarding mode"
      );
      return;
    }
    return dispatch(onboardingSetStep(onboardingController.getPrevStep()));
  };
};

export const onboardingSetStep = (
  step: OnboardingStep
): {
  type: string;
  payload?: OnboardingStep;
} => {
  const onboardingController = store.getState().auth
    .onboardingController as OnboardingController;
  onboardingController.setStep(step);
  return {
    type: actionTypes.AUTH_ONBOARDING_SET_STEP,
    payload: step
  };
};

const initializationFinished = (
  isAuthenticated: boolean
): { type: string; payload: boolean } => {
  return {
    type: actionTypes.AUTH_INITIALIZATION_FINISHED,
    payload: isAuthenticated
  };
};

const setupUser = (dispatch: ThunkDispatch<IRootState, void, AnyAction>) => {
  let refreshUserPhoneTimer: NodeJS.Timer | null = null;
  let phoneOfflineTimeout: NodeJS.Timer | null = null;
  const REFRESH_PHONE_NOTIFICATION_ID = "phone-not-connected";

  const checkPhoneOffline = () => {
    const userStatus = store.getState().auth.userStatus;
    // NOTE: refresh user status in case his phone not connected
    if (phoneOfflineTimeout) {
      clearTimeout(phoneOfflineTimeout);
      phoneOfflineTimeout = null;
    }
    if (userStatus && !userStatus.online && userStatus.phone) {
      // NOTE: delay phone offline notification
      // https://gitlab.iperitydev.com/compass/bridge/-/issues/705
      phoneOfflineTimeout = setTimeout(() => {
        phoneOfflineTimeout = null;
        dispatch(
          notificationShow({
            uid: REFRESH_PHONE_NOTIFICATION_ID,
            level: "info",
            dismissable: false,
            message:
              "Your phone appears to be offline. Check if it's correctly connected."
          })
        );
      }, 3000);
      scheduleRefreshUserStatus();
    } else {
      dispatch(notificationDismiss(REFRESH_PHONE_NOTIFICATION_ID));
    }
  };
  const scheduleRefreshUserStatus = () => {
    if (refreshUserPhoneTimer) {
      clearTimeout(refreshUserPhoneTimer);
    }
    refreshUserPhoneTimer = setTimeout(async () => {
      try {
        const updateUserStatusResp = await dispatch(updateUserStatus());
        if (
          updateUserStatusResp &&
          updateUserStatusResp.userStatus &&
          updateUserStatusResp.userStatus.online
        ) {
          dispatch(notificationDismiss(REFRESH_PHONE_NOTIFICATION_ID));
        } else {
          scheduleRefreshUserStatus();
        }
      } catch (error) {
        // NOTE: fail silently
        if (store.getState().auth.isAuthenticated) {
          scheduleRefreshUserStatus();
        }
      }
    }, 7000);
  };

  compassDataMiddleware.user$
    .pipe(compassDebouncePipe())
    .subscribe(async updatedUser => {
      const user = store.getState().auth.user;
      dispatch(updateUserInfo(updatedUser));
      if (
        user &&
        updatedUser.phoneId !== user.phoneId &&
        // NOTE: prevent showing log-in phone notification for fake onboarding user
        !store.getState().auth.onboardingMode
      ) {
        if (refreshUserPhoneTimer) {
          clearTimeout(refreshUserPhoneTimer);
        }
        try {
          const userStatus = await dispatch(updateUserStatus());
          if (userStatus) {
            dispatch(
              notificationShow({
                level: "success",
                autoDismiss: 3000,
                message: `You logged in to phone ${userStatus.phoneData.name}.`
              })
            );
            checkPhoneOffline();
          } else {
            dispatch(notificationDismiss(REFRESH_PHONE_NOTIFICATION_ID));
            dispatch(
              notificationShow({
                level: "success",
                autoDismiss: 3000,
                message: "You logged out of the phone."
              })
            );
          }
        } catch (error) {
          handleError(error);
        }
      } else {
        checkPhoneOffline();
      }
    });
};

const proceedLogin = async (
  username: string,
  password: string
): Promise<any> => {
  const dispatch: ThunkDispatch<IRootState, void, AnyAction> = store.dispatch;

  // TODO: remove timeout
  let timeout = false;
  const errorTimeout = setTimeout(() => {
    timeout = true;
  }, 30000);

  try {
    const connectInfo = await getConnectInfo(username, password);
    const connection = new Connection(connectInfo.server);
    await connection.connect(connectInfo.jid, password);
    if (timeout) {
      throw new Error();
    }
    const user = connection.model.getUserForJid(connectInfo.jid);
    const company: ICompassCompany = await connection.rest.getMyCompany();
    const apiVersion = await connection.rest.getApiVersion();
    dispatch(setApiVersion(apiVersion));
    saveUserCachedInfo({
      name: user.name,
      jid: user.jid,
      username: user.username,
      language: user.language,
      contact: user.contact
    });
    saveLoginCredentials(username, password);

    userStorage.init(connectInfo.jid);
    appUsage.init();

    dispatch(updateConnection(connection));
    dispatch(updateUserInfo(user));
    dispatch(updateUserCompanyAction(company));
    compassDataMiddleware.setSource(connection, connection.jid);

    setupUser(dispatch);
    setupCalls(dispatch);
    setupQueues(dispatch);
    setupContacts(dispatch);
    await Promise.all([
      dispatch(updateUserPermissions(company)),
      dispatch(updateUserStatus()),
      dispatch(updateUserFeatures()),
      loadUserPreferences()
    ]);
    setUserId(username);
  } catch (error) {
    clearTimeout(errorTimeout);
    // pass down error
    throw error;
  }
};

export const login = (
  username: string,
  password: string
): ThunkAction<Promise<boolean>, IAuthState, null, AnyAction> => {
  return (dispatch, getState, api) => {
    return new Promise((resolve, reject) => {
      dispatch(loginStarted());
      proceedLogin(username, password).then(
        () => {
          dispatch(loginFinished(true));
          resolve(true);
        },
        e => {
          handleError(e);
          clearLoginCredentials();
          clearUserCachedInfo();
          dispatch(loginFinished(false));
          dispatch(clearUserData());
          resolve(false);
        }
      );
    });
  };
};

export const authInitialize = (): ThunkAction<
  Promise<{ connected: boolean; authenticated: boolean }>,
  IAuthState,
  null,
  AnyAction
> => {
  return async dispatch => {
    dispatch(initializationStarted());
    const loginCredentials = getLoginCredentials();
    if (!loginCredentials.username || !loginCredentials.password) {
      dispatch(initializationFinished(false));
      return {
        connected: false,
        authenticated: false
      };
    }
    let connected = true;
    let authenticated = true;
    try {
      await proceedLogin(loginCredentials.username, loginCredentials.password);
    } catch (e) {
      if (
        e &&
        e.type === ErrorType.api &&
        e.error &&
        e.error.responseJSON &&
        e.error.responseJSON.code === 401
      ) {
        authenticated = false;
        handleError(e);
        clearLoginCredentials();
        clearUserCachedInfo();
      } else {
        const userInfo = getUserCachedInfo();
        if (userInfo) {
          dispatch(updateUserCachedInfo(userInfo));
        }
      }
      connected = false;
    }
    if (!authenticated) {
      dispatch(clearUserData());
    }
    dispatch(initializationFinished(true));
    return {
      connected,
      authenticated
    };
  };
};

export const logout = (guest?: boolean) => {
  compassDataMiddleware.reset();
  const connection = store.getState().auth.connection;
  if (connection && !guest) {
    connection.disconnect();
  }
  userStorage.reset();
  appUsage.reset();
  clearLoginCredentials();
  clearUserCachedInfo();
  store.dispatch(notificationsDismissAll());
  store.dispatch(unloadUserPreferences());
  store.dispatch(notificationsDismissAll());
  return clearUserData();
};

export const setUserStatus = (
  receiveCalls: ReceiveCalls,
  displayStatus?: string
): ThunkAction<Promise<boolean>, IRootState, void, AnyAction> => {
  return (dispatch, getState) => {
    let receiveCallsParam: string = "";
    switch (receiveCalls) {
      case ReceiveCalls.all:
        receiveCallsParam = "receiveAll";
        break;
      case ReceiveCalls.none:
        receiveCallsParam = "receiveNone";
        break;
      case ReceiveCalls.onlyDirect:
        receiveCallsParam = "receiveOnlyDirect";
        break;
    }
    const auth = getState().auth;
    const connection = auth.connection as Connection;
    const userId = parseInt((auth.user as User).id, 10);
    return wrapApiError(
      connection.rest.post(
        `${connection.rest.getUrlForObject("user", userId)}/status`,
        {
          receiveCalls: receiveCallsParam,
          displayStatus: displayStatus || ""
        }
      )
    );
  };
};

export const showAskFeedbackModal = (): { type: string } => {
  return {
    type: actionTypes.AUTH_SHOW_ASK_FEEDBACK_MODAL
  };
};

export const showFeedbackModal = (): { type: string } => {
  return {
    type: actionTypes.AUTH_SHOW_FEEDBACK_MODAL
  };
};

export const showLoginPhoneModal = (
  modalType: LoginPhoneModalType
): { type: string; payload: LoginPhoneModalType } => {
  return {
    type: actionTypes.AUTH_SHOW_LOGIN_PHONE_MODAL,
    payload: modalType
  };
};

export const closeLoginPhoneModal = (): { type: string } => {
  return {
    type: actionTypes.AUTH_CLOSE_LOGIN_PHONE_MODAL
  };
};

export const closeAskFeedbackModal = (): { type: string } => {
  return {
    type: actionTypes.AUTH_CLOSE_ASK_FEEDBACK_MODAL
  };
};

export const closeFeedbackModal = (): { type: string } => {
  return {
    type: actionTypes.AUTH_CLOSE_FEEDBACK_MODAL
  };
};

export const showOnboardingModal = (): { type: string } => {
  return {
    type: actionTypes.AUTH_SHOW_ONBOARDING_MODAL
  };
};

export const closeOnboardingModal = (): { type: string } => {
  return {
    type: actionTypes.AUTH_CLOSE_ONBOARDING_MODAL
  };
};
