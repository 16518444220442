import * as React from "react";
import "./Navbar.scss";
import NavbarDropdown, {
  IMenuItem,
  MenuItemType
} from "./NavbarDropdown/NavbarDropdown";
import { connect } from "react-redux";
import { navigationSet } from "src/store/actions/navigation";
import {
  logout,
  closeAskFeedbackModal,
  showFeedbackModal,
  beginOnboarding,
  setUserStatus,
  showLoginPhoneModal,
  logoutUserFromPhone
} from "src/store/actions/auth";
import { IRootState } from "src/store/reducers";
import Auxi from "src/hoc/Auxi/Auxi";
import { Call, ReceiveCalls, User } from "compass.js";
import IconInformationModal from "src/components/IconInformationModal/IconInformationModal";
import {
  NavigationPage,
  NavigationHomeList
} from "src/store/reducers/navigation";
import { IUserPreferences, ViewModeType } from "src/store/reducers/preferences";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { updateUserPreferences } from "src/store/actions/preferences";
import logoImg from "src/assets/imgs/bridge-logo-light.svg";
import { TrackAction, TrackCategory } from "src/utils/track";
import FeedbackFormModal from "../FeedbackFormModal/FeedbackFormModal";
import PrivacyStatementModal from "../PrivacyStatementModal/PrivacyStatementModal";
import PremiumFeaturesModal from "../PremiumFeaturesModal/PremiumFeaturesModal";
import { LoginPhoneModalType } from "../LoginPhoneModal/LoginPhoneModal";
import Modal from "src/components/UI/Modal/Modal";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserStatusInfo, UserStatus } from "src/utils/user";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons/faEllipsisH";
import { NavbarDropDownId } from "src/store/reducers/window";
import { isElectron } from "src/utils";
import { handleError } from "src/utils/errorHandler";
import { UserFeature, OnboardingType } from "src/store/reducers/auth";
import { syncAddressBookContacts } from "src/store/actions/contacts";
import { notificationShow } from "src/store/actions";
import { BridgeColor } from "src/utils/consts";
import packageJson from "../../../package.json";
import NavbarWrapUpBtn from "./NavbarWrapUpBtn/NavbarWrapUpBtn";

enum AppMenuActionId {
  contactsOverview = "contacts-overview",
  help = "help",
  syncContacts = "sync-contacts",
  toggleDefaultHomeList = "toggle-default-home-list",
  toggleViewMode = "toggle-default-view",
  beginOnboarding = "begin-onboarding",
  sendFeedback = "send-feedback",
  manual = "manual",
  privacy = "privacy",
  premium = "premium",
  logout = "logout"
}
enum StatusMenuActionId {
  available = "available",
  away = "away",
  busy = "busy",
  switchPhone = "switch-phone",
  logOutPhone = "log-out-phone"
}
const TRACK_CATEGORY = TrackCategory.navbar;

class Navbar extends React.Component<
  INavbarProps,
  {
    isHelpModalOpened: boolean;
    isPrivacyModalOpened: boolean;
    isPremiumFeaturesModalOpened: boolean;
    isLogoutPhoneModalOpened: boolean;
    isLogoutPhoneSubmitting: boolean;
  }
> {
  public state = {
    isHelpModalOpened: false,
    isPrivacyModalOpened: false,
    isPremiumFeaturesModalOpened: false,
    isLogoutPhoneModalOpened: false,
    isLogoutPhoneSubmitting: false
  };

  public render() {
    const statusMenuDropdownItems = this.getStatusMenuDropdownItems();
    const appMenuDropdownItems = this.getAppMenuDropdownItems();
    return (
      <Auxi>
        <div className="navbar navbar--placeholder" />
        <div className="navbar">
          <div className="navbar__container">
            <div className="navbar__logo">
              <img src={logoImg} alt="Bridge Logo" />
            </div>
            <div className="navbar__menu">
              {this.props.apiVersion && this.props.apiVersion >= 3 ? (
                <NavbarWrapUpBtn />
              ) : null}
              <NavbarDropdown
                items={statusMenuDropdownItems}
                onClick={this.onStatusItemClick}
                id={NavbarDropDownId.status}
              >
                <Auxi>
                  <div className="navbar__user-dropdown-title">
                    {!this.props.isWrapUp ? (
                      <div
                        className={
                          "navbar__user-dropdown-call-state navbar__user-dropdown-call-state--" +
                          this.props.userStatus
                        }
                      >
                        <FontAwesomeIcon icon={faCircle} />
                      </div>
                    ) : null}
                    {this.props.username}
                  </div>
                  <div className="navbar__user-dropdown-icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </Auxi>
              </NavbarDropdown>
              <NavbarDropdown
                items={appMenuDropdownItems}
                onClick={this.onAppMenuItemClick}
                id={NavbarDropDownId.app}
              >
                <div className="navbar__app-dropdown-icon">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </div>
              </NavbarDropdown>
            </div>
          </div>
        </div>
        <FeedbackFormModal isOpen={this.props.showFeedbackModal} />
        <Modal
          isOpen={this.state.isPremiumFeaturesModalOpened}
          title={"Upgrade Compass Bridge to get full access"}
          className={"modal--premium-features"}
          showCloseBtn={true}
          onRequestClose={this.handlePremiumFeaturesModalRequestClose}
        >
          <PremiumFeaturesModal />
        </Modal>
        <Modal
          isOpen={this.state.isHelpModalOpened}
          onRequestClose={this.handleHelpModalRequestClose}
          title={"Icon Information"}
          className={"modal--help-modal"}
          showCloseBtn={true}
        >
          <IconInformationModal />
        </Modal>
        <Modal
          isOpen={this.state.isPrivacyModalOpened}
          title={"Compass Bridge Privacy Policy"}
          onRequestClose={this.handlePrivacyModalRequestClose}
          showCloseBtn={true}
          className={"modal--privacy"}
        >
          <PrivacyStatementModal />
        </Modal>
        <Modal
          isOpen={this.state.isLogoutPhoneModalOpened}
          title={`Log out of phone`}
          onRequestClose={this.handleLogoutModalRequestClose}
          showCloseBtn={true}
          className={"modal--logout-phone"}
          buttons={[
            {
              text: "Don't log out",
              color: BridgeColor.gs1000,
              clicked: this.handleLogoutModalRequestClose,
              disabled: this.state.isLogoutPhoneSubmitting
            },
            {
              text: "Log out of my phone",
              color: BridgeColor.prim500,
              clicked: this.logoutPhone,
              disabled: this.state.isLogoutPhoneSubmitting
            }
          ]}
        >
          You are current logged in to {this.props.loggedInPhoneName}. Are you
          sure you want to log out of the phone?
        </Modal>
        <Modal
          isOpen={this.props.showAskFeedbackModal}
          title={"We value your opinion"}
          onRequestClose={this.props.onCloseAskFeedbackModal}
          showCloseBtn={true}
          className={"modal--ask-feedback"}
          buttons={[
            {
              text: "Give feedback",
              color: BridgeColor.prim500,
              clicked: this.props.onShowFeedbackModal
            },
            {
              text: "Not now",
              color: BridgeColor.gs1000,
              clicked: this.props.onCloseAskFeedbackModal
            }
          ]}
        >
          We are always looking for new ways to improve Bridge. Would you mind
          telling us what you think?
        </Modal>
      </Auxi>
    );
  }

  private logoutPhone = async () => {
    if (!this.props.userId) {
      return;
    }
    this.setState({ isLogoutPhoneSubmitting: true });
    try {
      await this.props.onLogoutUserFromPhone(this.props.userId);
    } catch (error) {
      this.setState({ isLogoutPhoneSubmitting: false });
    } finally {
      this.setState({
        isLogoutPhoneSubmitting: false,
        isLogoutPhoneModalOpened: false
      });
    }
  };

  private handleLogoutModalRequestClose = () => {
    this.setState({ isLogoutPhoneModalOpened: false });
  };

  private handleHelpModalRequestClose = () => {
    this.setState({ isHelpModalOpened: false });
  };

  private handlePrivacyModalRequestClose = () => {
    this.setState({ isPrivacyModalOpened: false });
  };

  private handlePremiumFeaturesModalRequestClose = () => {
    this.setState({ isPremiumFeaturesModalOpened: false });
  };

  private openPremiumFeaturesModal = () => {
    this.setState({ isPremiumFeaturesModalOpened: true });
  };

  private getStatusMenuDropdownItems = (): IMenuItem[] => {
    const statusTooltip = this.props.isLoggedInToThePhone
      ? undefined
      : "Log in to a phone to change status.";
    const statusItems: IMenuItem[] = [
      {
        type: MenuItemType.header,
        title: "Change status"
      },
      {
        action: StatusMenuActionId.available,
        type: MenuItemType.button,
        title: "Available",
        icon: faCircle,
        iconColor: BridgeColor.statusAvailable,
        selected:
          this.props.isLoggedInToThePhone &&
          this.props.receiveCallsStatus === ReceiveCalls.all,
        track: [TRACK_CATEGORY, TrackAction.navbarStatusAvailable],
        disabled: !this.props.isLoggedInToThePhone,
        tooltip: statusTooltip
      },
      {
        action: StatusMenuActionId.busy,
        type: MenuItemType.button,
        title: "No queue calls",
        icon: faCircle,
        iconColor: BridgeColor.statusBusy,
        selected:
          this.props.isLoggedInToThePhone &&
          this.props.receiveCallsStatus === ReceiveCalls.onlyDirect,
        track: [TRACK_CATEGORY, TrackAction.navbarStatusBusy],
        disabled: !this.props.isLoggedInToThePhone,
        tooltip: statusTooltip
      },
      {
        action: StatusMenuActionId.away,
        type: MenuItemType.button,
        title: "No calls",
        icon: faCircle,
        iconColor: BridgeColor.statusAway,
        selected:
          this.props.isLoggedInToThePhone &&
          this.props.receiveCallsStatus === ReceiveCalls.none,
        track: [TRACK_CATEGORY, TrackAction.navbarStatusAway],
        disabled: !this.props.isLoggedInToThePhone,
        tooltip: statusTooltip
      },
      {
        type: MenuItemType.separator
      }
    ];
    return [
      ...(this.props.apiVersion && this.props.apiVersion >= 3
        ? statusItems
        : []),
      {
        action: StatusMenuActionId.switchPhone,
        title: this.props.userHasPhone ? "Switch phones" : "Log in to a phone",
        type: MenuItemType.button,
        track: [
          TRACK_CATEGORY,
          this.props.userHasPhone
            ? TrackAction.navbarSwitchPhone
            : TrackAction.navbarLoginPhone
        ]
      },
      ...(this.props.userHasPhone
        ? [
            {
              action: StatusMenuActionId.logOutPhone,
              title: "Log out of phone",
              type: MenuItemType.button,
              track: [TRACK_CATEGORY, TrackAction.navbarLogoutPhone]
            } as IMenuItem
          ]
        : [])
    ];
  };

  private getAppMenuDropdownItems = (): IMenuItem[] => {
    let toggleDefaultHomeListLabel: string = "";
    switch (this.props.userPreferences.defaultHomeList) {
      case NavigationHomeList.contacts:
        toggleDefaultHomeListLabel = "Switch to Agent view";
        break;
      case NavigationHomeList.queues:
        toggleDefaultHomeListLabel = "Switch to Receptionist view";
        break;
    }
    let toggleViewModeLabel: string = "";
    switch (this.props.userPreferences.viewMode) {
      case ViewModeType.comfortable:
        toggleViewModeLabel = "Switch to Compact view";
        break;
      case ViewModeType.compact:
        toggleViewModeLabel = "Switch to Comfortable view";
        break;
    }
    return [
      {
        action: AppMenuActionId.contactsOverview,
        title: "Open full-screen contacts",
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarOpenFullScreenContacts]
      },
      {
        type: MenuItemType.separator
      },
      {
        action: AppMenuActionId.toggleDefaultHomeList,
        title: toggleDefaultHomeListLabel,
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarToggleDefaultHomeList],
        onboardingStep: OnboardingStepId.navbarView
      },
      {
        action: AppMenuActionId.toggleViewMode,
        title: toggleViewModeLabel,
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarToggleViewMode],
        onboardingStep: OnboardingStepId.navbarCompact
      },
      {
        type: MenuItemType.separator
      },
      ...(!this.props.onboardingMode
        ? [
            {
              action: AppMenuActionId.beginOnboarding,
              title: "Open tutorial",
              type: MenuItemType.button,
              track: [TRACK_CATEGORY, TrackAction.navbarOpenTutorial]
            } as IMenuItem
          ]
        : []),
      ...(isElectron()
        ? [
            {
              action: AppMenuActionId.syncContacts,
              title: "Sync contacts now",
              type: MenuItemType.button,
              disabled: this.props.addressBookContactsIsLoading,
              track: [TRACK_CATEGORY, TrackAction.navbarSyncContacts]
            } as IMenuItem
          ]
        : []),
      {
        action: AppMenuActionId.manual,
        title: "User manual",
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarManual]
      },
      {
        action: AppMenuActionId.help,
        title: "Help",
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarHelp],
        onboardingStep: OnboardingStepId.navbarHelp
      },
      {
        action: AppMenuActionId.privacy,
        title: "Privacy Policy",
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarPrivacyPolicy]
      },
      {
        action: AppMenuActionId.sendFeedback,
        title: "Send feedback",
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarFeedback],
        onboardingStep: OnboardingStepId.navbarSendFeedback
      },
      ...(!this.props.userFeatures.includes(UserFeature.callcontrol)
        ? [
            {
              action: AppMenuActionId.premium,
              title: "Bridge Premium",
              type: MenuItemType.button,
              track: [TRACK_CATEGORY, TrackAction.navbarPremium]
            } as IMenuItem
          ]
        : []),
      {
        type: MenuItemType.separator
      },
      {
        action: AppMenuActionId.logout,
        type: MenuItemType.button,
        title: "Sign out of application"
      }
    ];
  };

  private onStatusItemClick = (actionId: StatusMenuActionId): void => {
    switch (actionId) {
      case StatusMenuActionId.available:
        this.props.onSetUserStatus(ReceiveCalls.all).catch(handleError);
        break;
      case StatusMenuActionId.away:
        this.props.onSetUserStatus(ReceiveCalls.none).catch(handleError);
        break;
      case StatusMenuActionId.busy:
        this.props.onSetUserStatus(ReceiveCalls.onlyDirect).catch(handleError);
        break;
      case StatusMenuActionId.switchPhone:
        this.props.onShowLoginPhoneModal(LoginPhoneModalType.switch);
        break;
      case StatusMenuActionId.logOutPhone:
        this.setState({ isLogoutPhoneModalOpened: true });
        break;
    }
  };

  private onAppMenuItemClick = (actionId: AppMenuActionId): void => {
    switch (actionId) {
      case AppMenuActionId.contactsOverview:
        window.open(
          `${document.location.pathname}?contactsOverview=true`,
          "_blank",
          "location=no,scrollbars=yes,status=no"
        );
        break;
      case AppMenuActionId.help:
        this.setState({ isHelpModalOpened: true });
        break;
      case AppMenuActionId.syncContacts:
        this.props.onSyncAddressBookContacts().then(
          () => {
            this.props.onNotificationShow({
              message: "Contacts successfully updated.",
              level: "success",
              dismissable: true,
              autoDismiss: 4000
            });
          },
          () => {
            this.props.onNotificationShow({
              message:
                "There was an error while attempting to update contacts. Please try again later.",
              level: "danger",
              dismissable: true,
              autoDismiss: 4000
            });
          }
        );
        break;
      case AppMenuActionId.privacy:
        this.setState({ isPrivacyModalOpened: true });
        break;
      case AppMenuActionId.toggleDefaultHomeList:
        this.props.onUpdateUserPreferences({
          defaultHomeList:
            this.props.userPreferences.defaultHomeList ===
            NavigationHomeList.contacts
              ? NavigationHomeList.queues
              : NavigationHomeList.contacts
        });
        break;
      case AppMenuActionId.toggleViewMode:
        this.props.onUpdateUserPreferences({
          viewMode:
            this.props.userPreferences.viewMode === ViewModeType.comfortable
              ? ViewModeType.compact
              : ViewModeType.comfortable
        });
        break;
      case AppMenuActionId.beginOnboarding:
        this.props.onBeginOnboarding();
        break;
      case AppMenuActionId.sendFeedback:
        this.props.onShowFeedbackModal();
        break;
      case AppMenuActionId.manual:
        let manualUrl = "/docs";
        if (isElectron()) {
          manualUrl = `${packageJson.homepage}/docs`;
        }
        window.open(manualUrl, "_blank");
        break;
      case AppMenuActionId.premium:
        this.openPremiumFeaturesModal();
        break;
      case AppMenuActionId.logout:
        this.props.onNavigationSet(NavigationPage.login);
        this.props.onLogout();
        break;
    }
  };
}

interface IPropsFromState {
  userPreferences: IUserPreferences;
  userHasPhone: boolean;
  userId?: User["id"];
  loggedInPhoneName?: string;
  onboardingMode: boolean;
  // NOTE: added calls to trigger render on calls changing
  // to update user status
  calls: Call[];
  showAskFeedbackModal: boolean;
  showFeedbackModal: boolean;
  userFeatures: UserFeature[];
  userDisplayStatus?: string;
  username?: string;
  isConnected: boolean;
  addressBookContactsIsLoading: boolean;
  userStatus: UserStatus;
  receiveCallsStatus?: ReceiveCalls;
  apiVersion?: number;
  isLoggedInToThePhone: boolean;
  isWrapUp: boolean;
}

interface IPropsFromDispatch {
  onLogout: () => void;
  onNavigationSet: (page: NavigationPage, params?: any) => void;
  onUpdateUserPreferences: (
    userPreferences: Partial<IUserPreferences>
  ) => Promise<any>;
  onBeginOnboarding: () => void;
  onShowFeedbackModal: () => void;
  onCloseAskFeedbackModal: () => void;
  onSetUserStatus: (
    receiveCalls: ReceiveCalls,
    displayStatus?: string
  ) => Promise<any>;
  onShowLoginPhoneModal: (modalType: LoginPhoneModalType) => void;
  onSyncAddressBookContacts: () => Promise<any>;
  onNotificationShow: typeof notificationShow;
  onLogoutUserFromPhone: (userId: User["id"]) => Promise<any>;
}

interface INavbarProps extends IPropsFromState, IPropsFromDispatch {}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  let userStatus: UserStatus = UserStatus.loggedOut;
  if (state.auth.user) {
    userStatus = getUserStatusInfo(state.auth.user).userStatus;
  }
  const apiVersion = state.auth.apiVersion;
  return {
    userPreferences: state.preferences.user,
    userHasPhone: !!state.auth.phone,
    userId: state.auth.user ? state.auth.user.id : undefined,
    loggedInPhoneName: state.auth.phone && state.auth.phone.name,
    calls: state.calls.items,
    onboardingMode: state.auth.onboardingMode,
    showAskFeedbackModal: state.auth.showAskFeedbackModal,
    showFeedbackModal: state.auth.showFeedbackModal,
    userFeatures: state.auth.features,
    userDisplayStatus:
      state.auth.user && apiVersion && apiVersion >= 3
        ? state.auth.user.status.displayStatus
        : undefined,
    username: state.auth.user
      ? state.auth.user.name
      : state.auth.userCachedInfo && state.auth.userCachedInfo.username
      ? state.auth.userCachedInfo.username
      : "",
    addressBookContactsIsLoading: state.contacts.addressBookContactsIsLoading,
    userStatus,
    isConnected: !!state.auth.connection,
    receiveCallsStatus:
      state.auth.user && apiVersion && apiVersion >= 3
        ? state.auth.user.status.receiveCalls
        : undefined,
    apiVersion: state.auth.apiVersion,
    isLoggedInToThePhone: !!state.auth.phone,
    isWrapUp:
      !!state.auth.user &&
      !!apiVersion &&
      apiVersion >= 3 &&
      !!state.auth.user.status.wrapupState
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onLogout: () => dispatch(logout()),
    onNavigationSet: (page: NavigationPage, params?: any) =>
      dispatch(navigationSet(page, params)),
    onUpdateUserPreferences: (userPreferences: Partial<IUserPreferences>) =>
      dispatch(updateUserPreferences(userPreferences)),
    onBeginOnboarding: () => dispatch(beginOnboarding(OnboardingType.default)),
    onCloseAskFeedbackModal: () => dispatch(closeAskFeedbackModal()),
    onShowFeedbackModal: () => dispatch(showFeedbackModal()),
    onSetUserStatus: (receiveCalls: ReceiveCalls, displayStatus?: string) =>
      dispatch(setUserStatus(receiveCalls, displayStatus)),
    onShowLoginPhoneModal: (modalType: LoginPhoneModalType) =>
      dispatch(showLoginPhoneModal(modalType)),
    onSyncAddressBookContacts: () => dispatch(syncAddressBookContacts(true)),
    onNotificationShow: notification =>
      dispatch(notificationShow(notification)),
    onLogoutUserFromPhone: (userId: User["id"]) =>
      dispatch(logoutUserFromPhone(userId))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
