import * as React from "react";
import Home from "./containers/Home/Home";
import Login from "./containers/Login/Login";
import Loading from "./containers/Loading/Loading";
import ContactsOverview from "./containers/ContactsOverview/ContactsOverview";
import "./App.scss";
import { connect } from "react-redux";
import { authInitialize, beginOnboarding } from "./store/actions/auth";
import { IRootState } from "./store/reducers";
import { addLocaleData, IntlProvider } from "react-intl";
import * as en from "react-intl/locale-data/en";
import * as nl from "react-intl/locale-data/nl";
import * as enMessages from "./translations/locales/en.json";
import * as nlMessages from "./translations/locales/nl.json";
import { IHomePageParams, NavigationPage } from "./store/reducers/navigation";
import { navigationSet } from "./store/actions/navigation";
import Notifications from "./containers/Notifications/Notifications";
import {
  tickIntervalDestroy,
  tickIntervalInit
} from "./store/actions/tickInterval";
import { IUserPreferences } from "./store/reducers/preferences";
import { windowUpdateInit } from "./store/actions/window";
import { dialNumber, notificationShow } from "./store/actions";
import { isElectron } from "./utils";
import { DesktopEventType } from "src/utils";
import { handleError } from "./utils/errorHandler";
import Auxi from "src/hoc/Auxi/Auxi";
import {
  GlobalAlert,
  IGlobalAlertProps
} from "./components/GlobalAlert/GlobalAlert";
import {
  faExclamationTriangle
} from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { notificationDismiss } from "./store/actions/notifications";
import { INotification } from "./store/reducers/notifications";
import { OnboardingStepId } from "./utils/OnboardingStep";
import { trackPageView } from "./utils/track";
import { wrapOnboarding } from "./utils/onboarding";
import { ReceiveCalls, User } from "compass.js";
import { getMacOsAddressBook } from "./utils/contacts.macos";
import AwayStatusNotification
  from "./components/AwayStatusNotification/AwayStatusNotification";
import { OnboardingType } from "./store/reducers/auth";

addLocaleData(en);
addLocaleData(nl);

class App extends React.Component<IAppProps> {
  private currentTrackPage: string;

  public componentDidUpdate(prevProps: IAppProps) {
    if (
      this.props.isAuthenticated &&
      prevProps.receiveCalls !== this.props.receiveCalls
    ) {
      let notificationMessage: string = "";
      let notificationLevel: INotification["level"] = "info";
      switch (this.props.receiveCalls) {
        case ReceiveCalls.none:
          notificationMessage =
            "You set your status to No calls. You won't receive any calls until you manually change it.";
          break;
        case ReceiveCalls.onlyDirect:
          if (!this.props.isWrapUp) {
            notificationMessage =
              "You set your status to No queue calls. You will not receive queue calls until you manually change it.";
          }
          break;
        case ReceiveCalls.all:
          if (!prevProps.isWrapUp) {
            notificationMessage =
              "You set your status to Available. You will receive all calls.";
            notificationLevel = "success";
          }
          break;
      }
      if (notificationMessage) {
        this.props.onNotificationShow({
          uid: `user-status-notification`,
          message: notificationMessage,
          level: notificationLevel,
          autoDismiss: 3000,
          dismissable: true
        });
      }
    }
    if (
      !this.props.isAuthenticated &&
      this.props.navigationPage !== NavigationPage.login
    ) {
      this.props.onNavigationSet(NavigationPage.login);
    }
    if (
      !this.props.isConnected &&
      this.props.online &&
      !prevProps.online &&
      !this.props.authInitializationInProgress
    ) {
      this.initializeConnection();
    }
  }

  public componentDidMount() {
    windowUpdateInit();
    this.props.onTickIntervalInit();
    this.initializeConnection();
    // NOTE: make sure contacts permissions getting triggered
    // immediately after open the app
    if (isElectron() && window.remote.process.platform === "darwin") {
      try {
        getMacOsAddressBook().getMe();
      } catch (error) {
        console.error("failed to prompt contacts permissions");
      }
    }
  }

  public componentWillUnmount() {
    this.props.onTickIntervalDestroy();
  }

  public render() {
    const localeInfo = this.getLocaleInfo();
    const cssClasses = ["app", `app--${this.props.userPreferences.viewMode}`];
    const $globalAlert = this.$getGlobalAlert();
    let content: React.ReactNode = null;
    if ($globalAlert) {
      cssClasses.push("app--has-global-alert");
    }
    if (!this.props.navigationPage || this.props.authInitializationInProgress) {
      content = <Loading />;
    } else {
      switch (this.props.navigationPage) {
        case NavigationPage.home:
          content = <Home onReloadClick={this.initializeConnection} />;
          break;
        case NavigationPage.login:
          content = <Login />;
          break;
        case NavigationPage.contactsOverview:
          cssClasses.push("app--contacts-overview");
          content = <ContactsOverview />;
          break;
      }
      if (!this.props.onboardingMode) {
        this.trackPage();
      }
    }
    const $onboardingHook = <div className="app__onboarding-tooltip-hook" />;
    return (
      <IntlProvider
        locale={localeInfo.locale}
        messages={localeInfo.messages}
        key={localeInfo.locale}
      >
        <Auxi>
          {$globalAlert}
          <div className={cssClasses.join(" ")}>
            {content}
            {this.props.apiVersion && this.props.apiVersion >= 3 ? (
              <AwayStatusNotification />
            ) : null}
            <Notifications />
            {wrapOnboarding(
              $onboardingHook,
              [
                OnboardingStepId.finish,
                OnboardingStepId.finishNoPremium,
                OnboardingStepId.finishGuest,
                OnboardingStepId.endNoPremium,
                OnboardingStepId.welcome,
                OnboardingStepId.welcomeGuest
              ],
              {
                placement: "top",
                className: "app__onboarding-tooltip",
                arrow: false
              }
            )}
          </div>
        </Auxi>
      </IntlProvider>
    );
  }

  private initializeConnection = () => {
    this.props.onAuthInitialize().then(({ authenticated, connected }) => {
      if (isElectron()) {
        window.ipcRenderer.send(DesktopEventType.setupTelProtocol);
        window.ipcRenderer.on(DesktopEventType.tel, (e: any, data: any) => {
          if (!this.props.isConnected) {
            return;
          }
          this.props.onDialNumber(String(data.number)).catch(handleError);
        });
      }

      // Deeplink to start tutorial when opening app
      if (
        new URLSearchParams(location.search).get("startTutorial") !== null
      ) {
        this.props.onBeginTutorial();
        return;
      }

      if (authenticated) {
        const params: IHomePageParams = {
          list: this.props.userPreferences.defaultHomeList,
          dialerActive: false,
          detailsOpened: false
        };
        if (
          new URLSearchParams(location.search).get("contactsOverview") &&
          connected
        ) {
          this.props.onNavigationSet(NavigationPage.contactsOverview);
        } else {
          this.props.onNavigationSet(NavigationPage.home, params);
        }
      } else {
        this.props.onNavigationSet(NavigationPage.login);
      }
    });
  };

  private $getGlobalAlert(): JSX.Element | null {
    let globalAlertProps: IGlobalAlertProps | null = null;
    if (!this.props.online) {
      globalAlertProps = {
        message:
          "No internet connection. Please reconnect to be able to use Bridge.",
        level: "danger",
        icon: faExclamationTriangle
      };
    }
    return globalAlertProps ? <GlobalAlert {...globalAlertProps} /> : null;
  }

  private trackPage() {
    let trackPage: string | undefined;
    switch (this.props.navigationPage) {
      case NavigationPage.home:
        trackPage = `${this.props.navigationPage}-${
          (this.props.navigationParams as IHomePageParams).list
        }`;
        break;
      default:
        trackPage = this.props.navigationPage;
    }
    if (!trackPage || trackPage === this.currentTrackPage) {
      return;
    }
    this.currentTrackPage = trackPage;
    trackPageView(trackPage);
  }

  private getLocaleInfo(): { locale: string; messages: any } {
    // TODO: get language from user data
    const lang = new URLSearchParams(location.search).get("lang");
    let locale;
    let messages;
    switch (lang) {
      case "nl":
        locale = "nl";
        messages = nlMessages.default;
        break;
      default:
        locale = "en";
        messages = enMessages.default;
    }
    return {
      locale,
      messages
    };
  }
}

interface IPropsFromState {
  navigationPage?: NavigationPage;
  navigationParams: IHomePageParams;
  isAuthenticated: boolean;
  userPreferences: IUserPreferences;
  authInitializationInProgress: boolean;
  online: boolean;
  notifications: INotification[];
  onboardingMode: boolean;
  user?: User;
  isConnected: boolean;
  apiVersion?: number;
  receiveCalls?: ReceiveCalls;
  isWrapUp: boolean;
}

interface IPropsFromDispatch {
  onAuthInitialize: () => Promise<{
    connected: boolean;
    authenticated: boolean;
  }>;
  onDialNumber: (destination: string | number) => Promise<any>;
  onNavigationSet: (page: NavigationPage, params?: any) => void;
  onTickIntervalInit: () => void;
  onTickIntervalDestroy: () => void;
  onNotificationShow: typeof notificationShow;
  onNotificationDismiss: (uid: string) => void;
  onBeginTutorial: () => void;
}

interface IAppProps extends IPropsFromState, IPropsFromDispatch {}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  const user = state.auth.user;
  const isApiAbove2 = !!state.auth.apiVersion && state.auth.apiVersion >= 3;
  let receiveCalls: ReceiveCalls | undefined;
  let isWrapUp: boolean = false;
  if (user) {
    receiveCalls = isApiAbove2
      ? user.status.receiveCalls
      : state.queues.v2GlobalPause
      ? ReceiveCalls.onlyDirect
      : ReceiveCalls.all;
    isWrapUp = isApiAbove2 && !!user.status.wrapupState;
  }
  return {
    navigationPage: state.navigation.page,
    navigationParams: state.navigation.params as IHomePageParams,
    isAuthenticated: state.auth.isAuthenticated,
    userPreferences: state.preferences.user,
    authInitializationInProgress: state.auth.initializationInProgress,
    online: state.window.online,
    receiveCalls,
    notifications: state.notifications.items,
    onboardingMode: state.auth.onboardingMode,
    user: state.auth.user,
    isConnected: !!state.auth.connection,
    apiVersion: state.auth.apiVersion,
    isWrapUp
  };
};

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => {
  return {
    onAuthInitialize: () => dispatch(authInitialize()),
    onNavigationSet: (page: NavigationPage, params?: any) =>
      dispatch(navigationSet(page, params)),
    onTickIntervalInit: () => dispatch(tickIntervalInit()),
    onTickIntervalDestroy: () => dispatch(tickIntervalDestroy()),
    onNotificationShow: notification =>
      dispatch(notificationShow(notification)),
    onNotificationDismiss: uid => dispatch(notificationDismiss(uid)),
    onDialNumber: (destination: string) => dispatch(dialNumber(destination)),
    onBeginTutorial: () => dispatch(beginOnboarding(OnboardingType.guest))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(App);
