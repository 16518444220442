import * as React from "react";
import "./LoginPhoneModal.scss";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IRootState } from "src/store/reducers";
import { AnyAction } from "redux";
import {
  closeLoginPhoneModal,
  loginUserOnPhone,
  showLoginPhoneModal,
  logoutUserFromPhone
} from "src/store/actions/auth";
import {
  IPhoneData,
  ICompassCompany,
  ICompassPhone
} from "src/store/reducers/auth";
import { Connection, User } from "compass.js";
import { wrapApiError, handleError } from "src/utils/errorHandler";
import Auxi from "src/hoc/Auxi/Auxi";
import Button from "../UI/Button/Button";
import SearchInput from "../UI/SearchInput/SearchInput";
import {
  stringContains,
  compassObjUrlToID,
  sortBy,
  sortIgnoreCaseComparator
} from "src/utils";
import { Loader } from "../UI/Loader/Loader";
import Modal from "../UI/Modal/Modal";
import { IContact } from "src/store/reducers/contacts";
import { BridgeColor } from "src/utils/consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import { Tooltip } from "../UI/Tooltip/Tooltip";
import * as stable from "stable";

class LoginPhoneModal extends React.Component<
  ILoginPhoneModalProps,
  ILoginPhoneModalState
> {
  public state: ILoginPhoneModalState = {
    visibleContent: null,
    companyPhones: [],
    searchQuery: "",
    isLoading: true,
    isSubmitting: false
  };

  componentDidMount() {
    this.loadPhones();
  }

  componentDidUpdate(prevProps: ILoginPhoneModalProps) {
    if (
      this.props.type &&
      (!prevProps.type || prevProps.type !== this.props.type)
    ) {
      this.setState({
        visibleContent:
          this.props.type === LoginPhoneModalType.switch
            ? LoginPhoneModalVisibleContent.phoneSelect
            : LoginPhoneModalVisibleContent.start
      });
    }
    if (this.state.isLoading) {
      return;
    }
    const phoneId = this.props.phone ? this.props.phone.id : null;
    const prevPhoneId = prevProps.phone ? prevProps.phone.id : null;
    if (phoneId !== prevPhoneId) {
      this.loadPhones();
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showLoginPhoneModal}
        title={"Log in to a phone"}
        showCloseBtn={true}
        onRequestClose={this.closeModal}
      >
        {this.$getContent()}
      </Modal>
    );
  }

  private closeModal = () => {
    this.props.onCloseLoginPhoneModal();
    // NOTE: let play close animation before resetting content
    setTimeout(() => {
      this.setState({
        searchQuery: "",
        visibleContent: null
      });
    }, 500);
  };

  private $getContent() {
    if (this.state.isLoading) {
      return (
        <div className="login-phone-modal__loading">
          <Loader color="primary" />
        </div>
      );
    }
    if (!this.state.visibleContent) {
      return null;
    }
    if (!this.state.companyPhones.length) {
      return this.$getNoPhonesContent();
    }
    switch (this.state.visibleContent) {
      case LoginPhoneModalVisibleContent.start:
        return this.$getStartContent();
      case LoginPhoneModalVisibleContent.phoneSelect:
        return this.$getPhoneListContent();
    }
  }

  private loadPhones = async () => {
    if (!this.props.connection || !this.props.company) {
      return;
    }
    this.setState({
      isLoading: true
    });
    const rest = this.props.connection.rest;
    let companyPhones: ILoginPhoneModalState["companyPhones"] = [];
    try {
      const phones = await wrapApiError<ICompassPhone[]>(
        rest.get(
          `${rest.getUrlForObject(
            "company",
            this.props.company.entityId
          )}/phones`
        )
      );

      companyPhones = sortBy(
        phones.map(phone => {
          const phoneId = compassObjUrlToID(phone.self);
          const loggedInUser = phone.loggedInUser
            ? this.props.compassContacts[compassObjUrlToID(phone.loggedInUser)]
            : undefined;
          if (!loggedInUser) {
            return {
              id: phoneId,
              name: phone.name
            };
          }
          return {
            id: phoneId,
            name: phone.name,
            userName: loggedInUser.name,
            userId: parseInt(loggedInUser.id, 10)
          };
        }),
        item => item.name,
        sortIgnoreCaseComparator
      );
      companyPhones = stable(companyPhones, (a, b) => {
        const recentPhones = this.props.recentPhones;
        if (recentPhones.includes(a.id) && !recentPhones.includes(b.id)) {
          return -1;
        } else if (
          !recentPhones.includes(a.id) &&
          recentPhones.includes(b.id)
        ) {
          return 1;
        }
        if (recentPhones.indexOf(a.id) < recentPhones.indexOf(b.id)) {
          return -1;
        } else if (recentPhones.indexOf(a.id) > recentPhones.indexOf(b.id)) {
          return 1;
        }
        return 0;
      });
      // NOTE: place logged-in phone at the start of the list
      const loggedInPhoneIdx = companyPhones.findIndex(
        item => item.userId === this.props.userId
      );
      if (loggedInPhoneIdx > 0) {
        const loggedInPhone = companyPhones[loggedInPhoneIdx];
        companyPhones.splice(loggedInPhoneIdx, 1);
        companyPhones.unshift(loggedInPhone);
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({
        companyPhones,
        isLoading: false
      });
    }
  };

  private showPhoneContent = () => {
    this.setState({
      visibleContent: LoginPhoneModalVisibleContent.phoneSelect,
      searchQuery: ""
    });
  };

  private showStartContent = () => {
    this.setState({
      visibleContent: LoginPhoneModalVisibleContent.start,
      searchQuery: ""
    });
  };

  private onSearchInputChange = (searchQuery: string) => {
    this.setState({ searchQuery });
  };

  private onSelectPhone = (
    selectedPhone: ILoginPhoneModalState["selectedPhone"]
  ) => {
    if (this.state.isSubmitting) {
      return;
    }
    this.setState({
      selectedPhone
    });
  };

  private onLoginUserOnPhone = () => {
    const userId = this.props.userId;
    const selectedPhoneId =
      this.state.selectedPhone && this.state.selectedPhone.id;
    if (!userId || !selectedPhoneId) {
      return;
    }
    this.setState(
      {
        isSubmitting: true
      },
      async () => {
        try {
          await this.props.onLoginUserOnPhone(userId, selectedPhoneId);
          this.closeModal();
          this.loadPhones();
        } catch (error) {
          handleError(error);
        } finally {
          this.setState({
            isSubmitting: false
          });
        }
      }
    );
  };

  private onLogoutOfPhone = () => {
    const userPhone = this.props.phone;
    const userId = this.props.userId;
    if (!userPhone || !userId) {
      return;
    }
    this.setState(
      {
        isSubmitting: true
      },
      async () => {
        try {
          const wasLoggedToPhoneId = userPhone.id;
          await this.props.onLogoutUserFromPhone(userId);
          const companyPhones = this.state.companyPhones.slice();
          for (const phone of companyPhones) {
            if (phone.id === wasLoggedToPhoneId) {
              phone.userId = undefined;
              phone.userName = undefined;
              break;
            }
          }
          this.setState({
            companyPhones,
            isSubmitting: false
          });
        } catch (error) {
          this.setState({
            isSubmitting: false
          });
          handleError(error);
        }
      }
    );
  };

  private $getPhoneListContent = () => {
    return (
      <div className="login-phone-modal__select-phone">
        <SearchInput
          alwaysOpened={true}
          autoFocus={true}
          dark={true}
          changed={this.onSearchInputChange}
        />
        <div className="login-phone-modal__select-phone-items">
          {this.state.companyPhones
            .filter(item => stringContains(item.name, this.state.searchQuery))
            .map(companyPhone => {
              const cssClasses = ["login-phone-modal__select-phone-item"];
              if (
                this.state.selectedPhone &&
                this.state.selectedPhone.id === companyPhone.id
              ) {
                cssClasses.push(
                  "login-phone-modal__select-phone-item--selected"
                );
              }
              return (
                <div
                  key={companyPhone.id}
                  className={cssClasses.join(" ")}
                  onClick={this.onSelectPhone.bind(null, companyPhone)}
                >
                  <div className="login-phone-modal__select-phone-item-name">
                    {companyPhone.name}
                    {(!this.props.phone ||
                      this.props.phone.id !== companyPhone.id) &&
                    this.props.recentPhones.includes(companyPhone.id) ? (
                      <Tooltip content={"Your recent phone"}>
                        <span className="login-phone-modal__select-phone-item-recent-icon">
                          <FontAwesomeIcon icon={faHistory} />
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                  {companyPhone.userName && companyPhone.userId ? (
                    <div className="login-phone-modal__select-phone-item-user">
                      {this.props.phone &&
                      this.props.phone.id === companyPhone.id
                        ? "Your current phone"
                        : companyPhone.userName}
                    </div>
                  ) : null}
                </div>
              );
            })}
        </div>
        <div className="login-phone-modal__select-phone-buttons">
          <div className="login-phone-modal__select-phone-button-wrap">
            <Button
              color={BridgeColor.prim500}
              disabled={
                !this.state.selectedPhone ||
                // NOTE: user can only pick an unused phone
                !!(
                  this.state.selectedPhone && this.state.selectedPhone.userId
                ) ||
                !!(
                  this.state.selectedPhone &&
                  this.props.phone &&
                  this.state.selectedPhone.id === this.props.phone.id
                ) ||
                this.state.isSubmitting
              }
              onClick={this.onLoginUserOnPhone}
            >
              Select this phone
            </Button>
          </div>
          {this.props.phone ? (
            <div className="login-phone-modal__select-phone-button-wrap">
              <Button
                color={BridgeColor.gs800}
                disabled={this.state.isSubmitting}
                onClick={this.onLogoutOfPhone}
              >
                Log out of my phone
              </Button>
            </div>
          ) : null}
          <div className="login-phone-modal__select-phone-button-wrap">
            <Button
              color={BridgeColor.gs200}
              fill={"clear"}
              disabled={this.state.isSubmitting}
              onClick={
                this.props.type === LoginPhoneModalType.switch
                  ? this.closeModal
                  : this.showStartContent
              }
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  };

  private $getNoPhonesContent = () => {
    return (
      <div className="login-phone-modal__user-phone">
        {this.$getHeaderTxt()}
        <div className="login-phone-modal__user-phone-info">
          You currently don’t have any registered phones in Compass. Contact
          administrator or sign in to Compass and add a phone.
        </div>
        <div className="login-phone-modal__user-phone-buttons">
          <div className="login-phone-modal__user-phone-button-wrap">
            <Button color={BridgeColor.prim500} onClick={this.loadPhones}>
              Try again
            </Button>
          </div>
        </div>
      </div>
    );
  };

  private $getStartContent = () => {
    return (
      <div className="login-phone-modal__user-phone">
        {this.props.phone ? null : this.$getHeaderTxt()}
        <div className="login-phone-modal__user-phone-info">
          Please make sure that you have call waiting enabled for your phone to
          be able to make attended transfers. You can enable it in Phone
          settings in Compass.
        </div>

        <div className="login-phone-modal__user-phone-buttons">
          {this.props.phone ? (
            <Auxi>
              <div className="login-phone-modal__user-phone-button-wrap">
                <Button color={BridgeColor.prim500} onClick={this.closeModal}>
                  Continue with {this.props.phone.name}
                </Button>
              </div>
              <div className="login-phone-modal__user-phone-button-wrap">
                <Button
                  color={BridgeColor.gs200}
                  fill={"clear"}
                  onClick={this.showPhoneContent}
                >
                  Switch phone
                </Button>
              </div>
            </Auxi>
          ) : (
            <div className="login-phone-modal__user-phone-button-wrap">
              <Button
                color={BridgeColor.prim500}
                onClick={this.showPhoneContent}
              >
                Select a phone
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  private $getHeaderTxt = () => {
    return <div>You need to login into a phone to be able to use Bridge</div>;
  };
}

export enum LoginPhoneModalType {
  switch = "switch",
  login = "login"
}

enum LoginPhoneModalVisibleContent {
  start = "start",
  phoneSelect = "phoneSelect"
}

interface ILoginPhoneModalState {
  visibleContent: LoginPhoneModalVisibleContent | null;
  searchQuery: string;
  companyPhones: Array<{
    id: number;
    name: string;
    userId?: number;
    userName?: string;
  }>;
  selectedPhone?: {
    id: number;
    name: string;
    online: boolean;
    userId?: number;
    userName?: string;
  };
  isSubmitting: boolean;
  isLoading: boolean;
}

interface IPropsFromState {
  phone?: IPhoneData;
  connection?: Connection;
  company?: ICompassCompany;
  userId?: User["id"];
  userName?: string;
  type?: LoginPhoneModalType;
  showLoginPhoneModal: boolean;
  compassContacts: { [key: string]: IContact };
  recentPhones: number[];
}

interface IPropsFromDispatch {
  onCloseLoginPhoneModal: () => void;
  onLoginUserOnPhone: (userId: User["id"], phoneId: number) => Promise<any>;
  onLogoutUserFromPhone: (userId: User["id"]) => Promise<any>;
  onShowLoginPageModal: (type: LoginPhoneModalType) => void;
}

interface ILoginPhoneModalProps extends IPropsFromDispatch, IPropsFromState {}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    phone: state.auth.phone,
    connection: state.auth.connection,
    company: state.auth.company,
    userId: state.auth.user && state.auth.user.id,
    userName: state.auth.user && state.auth.user.name,
    type: state.auth.loginPhoneModalType,
    showLoginPhoneModal: state.auth.showLoginPhoneModal,
    compassContacts: state.contacts.compassItems,
    recentPhones: state.auth.recentPhones
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onCloseLoginPhoneModal: () => dispatch(closeLoginPhoneModal()),
    onShowLoginPageModal: type => dispatch(showLoginPhoneModal(type)),
    onLoginUserOnPhone: (userId: User["id"], phoneId: number) =>
      dispatch(loginUserOnPhone(userId, phoneId)),
    onLogoutUserFromPhone: (userId: User["id"]) =>
      dispatch(logoutUserFromPhone(userId))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(LoginPhoneModal);
