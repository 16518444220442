import { combineReducers } from "redux";
import contactsReducer, { IContactsState } from "./contacts";
import historyReducer, { IHistoryState } from "./history";
import queuesReducer, { IQueueState } from "./queues";
import callsReducer, { ICallsState } from "./calls";
import authReducer, { IAuthState } from "./auth";
import notificationsReducer, { INotificationsState } from "./notifications";
import navigationReducer, { INavigationState } from "./navigation";
import intervalReducer, { ITickIntervalState } from "./tickInterval";
import preferencesReducer, { IPreferencesState } from "./preferences";
import windowReducer, { IWindowState } from "./window";

export interface IRootState {
  readonly contacts: IContactsState;
  readonly history: IHistoryState;
  readonly queues: IQueueState;
  readonly calls: ICallsState;
  readonly auth: IAuthState;
  readonly notifications: INotificationsState;
  readonly navigation: INavigationState;
  readonly tickInterval: ITickIntervalState;
  readonly preferences: IPreferencesState;
  readonly window: IWindowState;
}

export interface IRootReducer {
  contacts: (state: IContactsState, action: any) => IContactsState;
  history: (state: IContactsState, action: any) => IHistoryState;
  queues: (state: IQueueState, action: any) => IQueueState;
  calls: (state: ICallsState, action: any) => ICallsState;
  auth: (state: IAuthState, action: any) => IAuthState;
  notifications: (
    state: INotificationsState,
    action: any
  ) => INotificationsState;
  navigation: (state: ICallsState, action: any) => INavigationState;
  tickInterval: (state: ITickIntervalState, action: any) => INavigationState;
  preferences: (state: IPreferencesState, action: any) => IPreferencesState;
  window: (state: IPreferencesState, action: any) => IWindowState;
}

export const rootReducer = combineReducers<IRootState, any>({
  contacts: contactsReducer,
  history: historyReducer,
  queues: queuesReducer,
  calls: callsReducer,
  auth: authReducer,
  notifications: notificationsReducer,
  navigation: navigationReducer,
  tickInterval: intervalReducer,
  preferences: preferencesReducer,
  window: windowReducer
});
