import * as React from "react";
import Modal from "src/components/UI/Modal/Modal";
import OnboardingQueuesImg from "src/assets/imgs/onboarding-queues.png";
import OnboardingContactsImg from "src/assets/imgs/onboarding-contacts.png";
import Button from "src/components/UI/Button/Button";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./OnboardingModal.scss";
import { userStorage } from "src/utils/userStorage";
import { NavigationHomeList } from "src/store/reducers/navigation";
import { connect } from "react-redux";
import { homeChangeList } from "src/store/actions/navigation";
import { IUserPreferences } from "src/store/reducers/preferences";
import { updateUserPreferences } from "src/store/actions/preferences";
import Auxi from "src/hoc/Auxi/Auxi";
import { BridgeColor } from "src/utils/consts";
import {
  beginOnboarding,
  showLoginPhoneModal,
  closeOnboardingModal
} from "src/store/actions/auth";
import { ONBOARDING_MODAL_SHOWN_KEY } from "src/utils/consts";
import { LoginPhoneModalType } from "../LoginPhoneModal/LoginPhoneModal";
import { OnboardingType } from "src/store/reducers/auth";
import { IRootState } from "src/store/reducers";

class OnboardingModalComponent extends React.PureComponent<
  IOnboardingModalComponentProps,
  IOnboardingModalState
> {
  state: IOnboardingModalState = {};
  private flowSelectOptions: IFlowSelectOptions[] = [
    {
      image: OnboardingContactsImg,
      title: "Manage contacts",
      value: NavigationHomeList.contacts
    },
    {
      image: OnboardingQueuesImg,
      title: "Manage queues",
      value: NavigationHomeList.queues
    }
  ];

  public render() {
    return (
      <Auxi>
        <Modal
          isOpen={this.props.showOnboardingModal}
          className={"modal--onboarding"}
        >
          <div className="onboarding-modal">
            <div className="onboarding-modal__title">
              Welcome to Compass Bridge!
            </div>
            <div className="onboarding-modal__description">
              With Compass Bridge, you can manage calls and call flows while
              having a full overview of your contacts and queues.
            </div>
            <div className="onboarding-modal__subtitle">
              What do you do more often?
            </div>
            {this.$getFlowSelect()}
            {this.$getSubmitBtn()}
            <div className="onboarding-modal__hint">
              Don't worry, you can always change this in settings later.
            </div>
          </div>
        </Modal>
      </Auxi>
    );
  }

  private $getFlowSelect() {
    return (
      <div className="onboarding-modal__flow-select">
        {this.flowSelectOptions.map((item, idx) => {
          const cssClasses = ["onboarding-modal__flow-select-item"];
          const isSelected = item.value === this.state.selectedFlow;
          if (isSelected) {
            cssClasses.push("onboarding-modal__flow-select-item--active");
          }
          return (
            <div
              key={idx}
              className={cssClasses.join(" ")}
              onClick={this.selectFlow.bind(null, item.value)}
            >
              <div className="onboarding-modal__flow-select-item-img">
                <img src={item.image} />
                <div className="onboarding-modal__flow-select-item-img-icon">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              </div>
              <div className="onboarding-modal__flow-select-item-title">
                {item.title}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  private selectFlow = (flow: NavigationHomeList) => {
    this.setState({
      selectedFlow: flow
    });
  };

  private $getSubmitBtn() {
    let btnText = "Start";
    if (this.state.selectedFlow) {
      switch (this.state.selectedFlow) {
        case NavigationHomeList.queues:
          btnText = "Start with contact centre";
          break;
        case NavigationHomeList.contacts:
          btnText = "Start with reception";
          break;
      }
    }
    return (
      <div className="onboarding-modal__submit-button">
        <Button
          color={
            this.state.selectedFlow ? BridgeColor.prim500 : BridgeColor.gs200
          }
          disabled={!this.state.selectedFlow}
          onClick={this.state.selectedFlow ? this.onSubmit : undefined}
        >
          {btnText}
        </Button>
      </div>
    );
  }

  private onSubmit = () => {
    if (!this.state.selectedFlow) {
      return;
    }
    this.props.onHomeChangeList(this.state.selectedFlow);
    this.props.onUpdateUserPreferences({
      defaultHomeList: this.state.selectedFlow
    });
    this.props.onCloseOnboardingModal();
    this.props.onBeginOnboarding(OnboardingType.default);
    userStorage.setItem(ONBOARDING_MODAL_SHOWN_KEY, true);
  };
}

interface IOnboardingModalState {
  selectedFlow?: NavigationHomeList;
}

interface IFlowSelectOptions {
  title: string;
  value: NavigationHomeList;
  image: string;
}

interface IOnboardingModalComponentProps
  extends IPropsFromState,
    IPropsFromDispatch {}

interface IPropsFromState {
  showOnboardingModal: boolean;
}

interface IPropsFromDispatch {
  onHomeChangeList: (list: NavigationHomeList) => void;
  onUpdateUserPreferences: (
    userPreferences: Partial<IUserPreferences>
  ) => Promise<any>;
  onBeginOnboarding: (type: OnboardingType) => void;
  onShowLoginPhoneModal: (modalType: LoginPhoneModalType) => void;
  onCloseOnboardingModal: () => void;
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    showOnboardingModal: state.auth.showOnboardingModal
  };
};

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => {
  return {
    onHomeChangeList: (list: NavigationHomeList) =>
      dispatch(homeChangeList(list)),
    onUpdateUserPreferences: (userPreferences: Partial<IUserPreferences>) =>
      dispatch(updateUserPreferences(userPreferences)),
    onBeginOnboarding: type => dispatch(beginOnboarding(type)),
    onShowLoginPhoneModal: (modalType: LoginPhoneModalType) =>
      dispatch(showLoginPhoneModal(modalType)),
    onCloseOnboardingModal: () => dispatch(closeOnboardingModal())
  };
};

export const OnboardingModal = connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingModalComponent);
