export const NORMAL_LIST_ITEM_HEIGHT = 57;
export const COMPACT_LIST_ITEM_HEIGHT = 37;

export const INFINITE_LIST_BREAKPOINT = 25;

export const ONBOARDING_MODAL_SHOWN_KEY = "onboardingModalShown:v3";

export const DEFAULT_COMPASS_DATA_SAMPLE_TIME = 200;

export enum BridgeColor {
  statusAvailable = "status-available",
  statusLoggedOut = "status-logged-out",
  statusInCall = "status-in-call",
  statusBusy = "status-busy",
  statusAway = "status-away",

  gs0 = "gs-0",
  gs100 = "gs-100",
  gs200 = "gs-200",
  gs300 = "gs-300",
  gs400 = "gs-400",
  gs500 = "gs-500",
  gs600 = "gs-600",
  gs700 = "gs-700",
  gs800 = "gs-800",
  gs900 = "gs-900",
  gs1000 = "gs-1000",

  prim200 = "prim-200",
  prim300 = "prim-300",
  prim400 = "prim-400",
  prim500 = "prim-500",
  prim600 = "prim-600",
  prim700 = "prim-700",

  sec300 = "sec-300",
  sec400 = "sec-400",
  sec500 = "sec-500",
  sec600 = "sec-600",
  sec700 = "sec-700",

  amber100 = "amber-100",
  amber500 = "amber-500",
  amber700 = "amber-700",
  green100 = "green-100",
  green500 = "green-500",
  green700 = "green-700",

  red100 = "red-100",
  red500 = "red-500",
  red700 = "red-700"
}
