export const CONTACTS_COMPASS_UPDATE = "CONTACTS_COMPASS_UPDATE";
export const CONTACTS_ADDRESS_BOOK_UPDATE = "CONTACTS_ADDRESS_BOOK_UPDATE";
export const CONTACTS_COMPASS_UPDATE_PINNED = "CONTACTS_LOAD_PINNED";
export const CONTACTS_ADDRESS_BOOK_SET_LOADED =
  "CONTACTS_ADDRESS_BOOK_SET_LOADED";
export const CONTACTS_ADDRESS_BOOK_LOADING_START =
  "CONTACTS_ADDRESS_BOOK_LOADING_START";
export const CONTACTS_ADDRESS_BOOK_LOADING_END =
  "CONTACTS_ADDRESS_BOOK_LOADING_END";

export const QUEUES_UPDATE = "QUEUES_UPDATE";
export const QUEUES_SET_GLOBAL_PAUSE = "QUEUES_SET_GLOBAL_PAUSE";
export const QUEUES_UPDATE_PINNED = "QUEUES_UPDATE_PINNED";
export const QUEUES_ACTION_STARTED = "QUEUES_ACTION_STARTED";
export const QUEUES_ACTION_COMPLETED = "QUEUES_ACTION_COMPLETED";
export const QUEUES_SET_QUEUE_TARGET_STATE = "QUEUES_SET_QUEUE_TARGET_STATE";
export const QUEUES_CLEAR_QUEUE_TARGET_STATE =
  "QUEUES_CLEAR_QUEUE_TARGET_STATE";

export const CALLS_UPDATE = "CALLS_UPDATE";
export const CALLS_UPDATE_ALL = "CALLS_UPDATE_ALL";
export const CALLS_ADD_ENDED = "CALLS_ADD_ENDED";
export const CALLS_REMOVE_ENDED = "CALLS_REMOVE_ENDED";
export const CALLS_ACTION_STARTED = "CALLS_ACTION_STARTED";
export const CALLS_ACTION_COMPLETED = "CALLS_ACTION_COMPLETED";
export const CALLS_START_TRANSFERRING = "CALLS_START_TRANSFERRING";
export const CALLS_DISMISS_TRANSFERRING = "CALLS_DISMISS_TRANSFERRING";
export const CALLS_ANSWER_DIALLER_CALL_START =
  "CALLS_ANSWER_DIALLER_CALL_START";
export const CALLS_ANSWER_DIALLER_CALL_FINISH =
  "CALLS_ANSWER_DIALLER_CALL_FINISH";
export const CALLS_REGISTER_ATTENDED_TRANSFER =
  "CALLS_REGISTER_ATTENDED_TRANSFER";
export const CALLS_ADD_HIGHLIGHTED_CALL = "CALLS_ADD_HIGHLIGHTED_CALL";
export const CALLS_REMOVE_HIGHLIGHTED_CALL = "CALLS_REMOVE_HIGHLIGHTED_CALL";
export const CALLS_REGISTER_ATTENDED_TRANSFER_DESTINATION =
  "CALLS_REGISTER_ATTENDED_TRANSFER_DESTINATION";
export const CALLS_CANCEL_ATTENDED_TRANSFER_DESTINATION =
  "CALLS_CANCEL_ATTENDED_TRANSFER_DESTINATION";

export const HISTORY_FETCH = "HISTORY_FETCH";

export const AUTH_LOGIN_STARTED = "AUTH_LOGIN_STARTED";
export const AUTH_LOGIN_FINISHED = "AUTH_LOGIN_FINISHED";
export const AUTH_CLEAR_USER_DATA = "AUTH_CLEAR_USER_DATA";
export const AUTH_UPDATE_USER_FEATURES = "AUTH_UPDATE_USER_FEATURES";
export const AUTH_UPDATE_USER_INFO = "AUTH_UPDATE_USER_INFO";
export const AUTH_UPDATE_USER_COMPANY = "AUTH_UPDATE_USER_COMPANY";
export const AUTH_UPDATE_USER_COMPANY_PERMISSION =
  "AUTH_UPDATE_USER_COMPANY_PERMISSION";
export const AUTH_UPDATE_CONNECTION = "AUTH_UPDATE_CONNECTION";
export const AUTH_INITIALIZATION_STARTED = "AUTH_INITIALIZATION_STARTED";
export const AUTH_INITIALIZATION_FINISHED = "AUTH_INITIALIZATION_FINISHED";
export const AUTH_START_ONBOARDING = "AUTH_START_ONBOARDING";
export const AUTH_STOP_ONBOARDING = "AUTH_STOP_ONBOARDING";
export const AUTH_ONBOARDING_SET_STEP = "AUTH_ONBOARDING_SET_STEP";
export const AUTH_SHOW_ASK_FEEDBACK_MODAL = "AUTH_SHOW_ASK_FEEDBACK_MODAL";
export const AUTH_CLOSE_ASK_FEEDBACK_MODAL = "AUTH_CLOSE_ASK_FEEDBACK_MODAL";
export const AUTH_SHOW_FEEDBACK_MODAL = "AUTH_SHOW_FEEDBACK_MODAL";
export const AUTH_CLOSE_FEEDBACK_MODAL = "AUTH_CLOSE_FEEDBACK_MODAL";
export const AUTH_SHOW_LOGIN_PHONE_MODAL = "AUTH_SHOW_LOGIN_PHONE_MODAL";
export const AUTH_CLOSE_LOGIN_PHONE_MODAL = "AUTH_CLOSE_LOGIN_PHONE_MODAL";
export const AUTH_SET_PHONE_DATA = "AUTH_SET_PHONE_DATA";
export const AUTH_SET_USER_STATUS = "AUTH_SET_USER_STATUS";
export const AUTH_UPDATE_USER_CACHED_INFO = "AUTH_UPDATE_USER_CACHED_INFO";
export const AUTH_SHOW_ONBOARDING_MODAL = "AUTH_SHOW_ONBOARDING_MODAL";
export const AUTH_CLOSE_ONBOARDING_MODAL = "AUTH_CLOSE_ONBOARDING_MODAL";
export const AUTH_SET_RECENT_PHONES = "AUTH_SET_RECENT_PHONES";
export const AUTH_SET_API_VERSION = "AUTH_SET_API_VERSION";

export const NOTIFICATIONS_SHOW = "NOTIFICATION_SHOW";
export const NOTIFICATIONS_DISMISS = "NOTIFICATION_REMOVE";
export const NOTIFICATIONS_DISMISS_ALL = "NOTIFICATIONS_DISMISS_ALL";

export const NAVIGATION_SET = "NAVIGATION_SET";
export const NAVIGATION_UPDATE_PARAMS = "NAVIGATION_UPDATE_PARAMS";
export const NAVIGATION_REPLACE_PARAMS = "NAVIGATION_REPLACE_PARAMS";

export const NAVIGATION_HOME_TOGGLE_DIALER = "NAVIGATION_HOME_TOGGLE_DIALER";
export const NAVIGATION_HOME_SHOW_DETAILS = "NAVIGATION_HOME_SHOW_DETAILS";
export const NAVIGATION_HOME_HIDE_DETAILS = "NAVIGATION_HOME_HIDE_DETAILS";
export const NAVIGATION_HOME_CHANGE_LIST = "NAVIGATION_HOME_CHANGE_LIST";

export const TICK_INTERVAL_REGISTER = "TICK_INTERVAL_REGISTER";
export const TICK_INTERVAL_UNREGISTER = "TICK_INTERVAL_UNREGISTER";
export const TICK_INTERVAL_INIT = "TICK_INTERVAL_INIT";
export const TICK_INTERVAL_DESTROY = "TICK_INTERVAL_DESTROY";
export const TICK_INTERVAL_PAUSE = "TICK_INTERVAL_PAUSE";
export const TICK_INTERVAL_UNPAUSE = "TICK_INTERVAL_UNPAUSE";

export const PREFERENCES_UPDATE_USER = "PREFERENCES_UPDATE_USER";
export const PREFERENCES_CLEAR_USER = "PREFERENCES_CLEAR_USER";

export const WINDOW_SIZE_UPDATE = "WINDOW_SIZE_UPDATE";
export const WINDOW_ONLINE_UPDATE = "WINDOW_ONLINE_UPDATE";
export const WINDOW_SHOW_NAVBAR_DROPDOWN = "NAVIGATION_SHOW_NAVBAR_DROPDOWN";
export const WINDOW_HIDE_NAVBAR_DROPDOWN = "NAVIGATION_HIDE_NAVBAR_DROPDOWN";
