
function getQueryParams() {
  return new URLSearchParams(location.search);
}

/**
 * Return the login environment from the URL, if any was specified
 * (using ?env=xxx)
 */
export const getLoginEnvironmentFromUrl = () => getQueryParams().get('env');
