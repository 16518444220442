import * as React from "react";
import "./Input.scss";

function changed(this: any, e: any) {
  this.changed(e, this.elementId);
}

const input: React.FC<any> = (props: any) => {
  let inputElement: React.ReactNode = null;
  const inputClasses = ["br-input__element"];

  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push("br-input__element--invalid");
  }

  switch (props.elementType) {
    case "input":
      inputElement = (
        <input
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={changed.bind(props)}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <textarea
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={changed.bind(props)}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          className={inputClasses.join(" ")}
          value={props.value}
          onChange={changed.bind(props)}
        >
          {props.elementConfig.options.map((option: any) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    default:
      inputElement = (
        <input
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={changed.bind(props)}
        />
      );
  }

  return (
    <div className="br-input">
      <label className="br-input__label">{props.label}</label>
      {inputElement}
    </div>
  );
};

export default input;
