import * as React from "react";
import "./FeedbackFormModal.scss";
import Modal from "src/components/UI/Modal/Modal";
import { Formik, Form, Field, FormikProps } from "formik";
import * as Yup from "yup";
import { InputWrapper } from "../UI/InputWrapper/InputWrapper";
import { InputLabel } from "../UI/InputLabel/InputLabel";
import { FormError } from "../UI/FormError/FormError";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IRootState } from "src/store/reducers";
import { AnyAction } from "redux";
import { closeFeedbackModal } from "src/store/actions/auth";
import { notificationShow } from "src/store/actions";
import { getPageScreenshot } from "src/utils";
import Lightbox from "react-image-lightbox";
import Auxi from "src/hoc/Auxi/Auxi";
import axios from "axios";
import "react-image-lightbox/style.css";
import { User } from "compass.js";
import { handleError, wrapApiError } from "src/utils/errorHandler";
import { appUsage } from "src/utils/appUsage";
import { BridgeColor } from "src/utils/consts";

const ZENDESK_API_ENDPOINT = "https://iperity.zendesk.com";

export class FeedbackFormModal extends React.Component<
  IFeedbackFormProps,
  IFeedbackFormState
> {
  public state: IFeedbackFormState = {
    screenshot: undefined,
    lightBoxOpen: false
  };
  private feedbackFormSchema = Yup.object().shape<IFeedbackFormSchema>({
    message: Yup.string().required("Message is required"),
    email: Yup.string().email("Invalid email"),
    attachScreenshot: Yup.bool()
  });
  private formRef: Formik<IFeedbackFormSchema> | null;
  private screenshotIsLoading: boolean = false;

  public render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        title={"We value your opinion."}
        showCloseBtn={true}
        onRequestClose={this.modalRequestCloseHandler}
        buttons={[
          {
            color: BridgeColor.prim500,
            disabled: this.formRef ? this.formRef.state.isSubmitting : false,
            clicked: () => {
              if (this.formRef) {
                this.formRef.submitForm();
              }
            },
            text: "Send my feedback"
          }
        ]}
      >
        <div className="feedback-form-modal">
          <div className="feedback-form-modal__description">
            We are always looking for the ways to improve our application. If
            you have feedback about this application that you are willing to
            share we would be interested in hearing it.
          </div>
          <div className="feedback-form-modal__form">
            <Formik
              initialValues={{
                message: "",
                email: "",
                attachScreenshot: false
              }}
              validationSchema={this.feedbackFormSchema}
              onSubmit={this.onFormSubmit}
              ref={formRef => (this.formRef = formRef)}
              render={this.$getForm}
            />
          </div>
        </div>
      </Modal>
    );
  }

  private modalRequestCloseHandler = () => {
    this.resetState().then(() => {
      if (this.formRef) {
        this.formRef.resetForm();
        this.formRef.forceUpdate();
      }
      this.props.onCloseFeedbackModal();
    });
  };

  private loadScreenshot = async () => {
    this.screenshotIsLoading = true;
    const screenshot = await getPageScreenshot();
    if (screenshot) {
      this.setState(
        {
          screenshot
        },
        () => {
          this.screenshotIsLoading = false;
        }
      );
    } else {
      this.screenshotIsLoading = false;
    }
  };

  private setFormSubmittingState = (val: boolean) => {
    if (!this.formRef) {
      return;
    }
    this.formRef.setSubmitting(val);
    this.forceUpdate();
  };

  private onFormSubmit = (values: IFeedbackFormSchema) => {
    this.setFormSubmittingState(true);
    const name = this.props.user.name;
    const username = this.props.user.username;
    wrapApiError(
      axios.post(`${ZENDESK_API_ENDPOINT}/api/v2/requests.json`, {
        request: {
          requester: {
            name,
            username,
            email: values.email ? values.email : undefined
          },
          subject: `Compass Bridge feedback from ${username}`,
          comment: { body: values.message },
          priority: "normal",
          custom_fields: [
            {
              id: 27864531,
              value: "bridge"
            }
          ]
        }
      })
    )
      .then(() => appUsage.logSentFeedback())
      .then(() => {
        this.setFormSubmittingState(false);
        this.props.onCloseFeedbackModal();
        this.props.onNotificationShow({
          message:
            "Your message was successfully sent. Thank you for your feedback!",
          level: "success",
          autoDismiss: 3000,
          dismissable: true
        });
      })
      .catch(e => {
        this.setFormSubmittingState(false);
        handleError(e);
      });
  };

  private $getForm = (props: FormikProps<IFeedbackFormSchema>) => {
    if (
      props.values.attachScreenshot &&
      !this.state.screenshot &&
      !this.screenshotIsLoading
    ) {
      this.loadScreenshot();
    }
    return (
      <Form className="feedback-form-modal__form">
        <InputWrapper invalid={props.touched.message && !!props.errors.message}>
          <InputLabel for={"feedbackForm__message"}>Your message</InputLabel>
          <Field
            name="message"
            component="textarea"
            id="feedbackForm__message"
          />
          {this.formRef &&
          this.formRef.state.submitCount > 0 &&
          props.errors.message ? (
            <FormError>{props.errors.message}</FormError>
          ) : null}
        </InputWrapper>
        <InputWrapper invalid={props.touched.email && !!props.errors.email}>
          <InputLabel for={"feedbackForm__email"}>
            Email
            <br />
            <span className="br-color--grey-1">
              This is optional, you can fill it in if you would like to hear
              back from us
            </span>
          </InputLabel>
          <Field name="email" type="email" id="feedbackForm__email" />
          {this.formRef &&
          this.formRef.state.submitCount > 0 &&
          props.touched.email &&
          props.errors.email ? (
            <FormError>{props.errors.email}</FormError>
          ) : null}
        </InputWrapper>
        {/* TODO: uncomment on https://gitlab.iperitydev.com/compass/bridge/-/issues/340 */}
        {/* <InputWrapper type={"checkbox"}>
          <InputLabel for={"feedbackForm__attachScreenshot"}>
            Attach my screenshot
          </InputLabel>
          <Field
            name="attachScreenshot"
            type="checkbox"
            id="feedbackForm__attachScreenshot"
          />
        </InputWrapper> */}
        {props.values.attachScreenshot ? this.$getScreenshot() : null}
      </Form>
    );
  };

  private $getScreenshot = () => {
    return !!this.state.screenshot ? (
      <Auxi>
        <img
          src={this.state.screenshot}
          className="feedback-form-modal__screenshot"
          onClick={this.openLightBox}
        />
        {this.state.lightBoxOpen ? (
          <Lightbox
            wrapperClassName="feedback-form-modal__lightbox"
            mainSrc={this.state.screenshot}
            onCloseRequest={this.closeLightBox}
          />
        ) : null}
      </Auxi>
    ) : null;
  };

  private openLightBox = () => {
    this.setState({
      lightBoxOpen: true
    });
  };

  private closeLightBox = () => {
    this.setState({
      lightBoxOpen: false
    });
  };

  private resetState = () => {
    return new Promise(resolve => {
      this.setState(
        {
          screenshot: undefined,
          lightBoxOpen: false
        },
        resolve
      );
    });
  };
}

interface IFeedbackFormSchema {
  message: string;
  email: string;
  attachScreenshot: boolean;
}

interface IFeedbackFormState {
  screenshot?: string;
  lightBoxOpen: boolean;
}

interface IPropsFromState {
  user: User;
}

interface IPropsFromDispatch {
  onCloseFeedbackModal: () => void;
  onNotificationShow: typeof notificationShow;
}

interface IFeedbackFormProps extends IPropsFromDispatch, IPropsFromState {
  isOpen: boolean;
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    user: state.auth.user as User
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onCloseFeedbackModal: () => dispatch(closeFeedbackModal()),
    onNotificationShow: notification => dispatch(notificationShow(notification))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackFormModal);
