import { store } from "src/store/index";
import { AnyAction } from "redux";
import * as actionTypes from "./actionTypes";
import { IUserPreferences } from "src/store/reducers/preferences";
import { ThunkAction } from "redux-thunk";
import { IRootState } from "src/store/reducers";
import { userStorage } from "src/utils/userStorage";

const USER_PREFERENCES_STORAGE_KEY = "userPreferences";

const updateUserPreferencesState = (
  newPreferences: IUserPreferences
): { type: string; payload: IUserPreferences } => {
  return {
    type: actionTypes.PREFERENCES_UPDATE_USER,
    payload: newPreferences
  };
};

export const unloadUserPreferences = (): { type: string } => {
  return {
    type: actionTypes.PREFERENCES_CLEAR_USER
  };
};

export const loadUserPreferences = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    userStorage
      .getItem(USER_PREFERENCES_STORAGE_KEY)
      .then((preferences: IUserPreferences | null) => {
        if (!preferences) {
          return resolve();
        }
        store.dispatch(updateUserPreferencesState(preferences));
        resolve();
      }, reject);
  });
};

export const updateUserPreferences = (
  preferences: Partial<IUserPreferences>
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return (dispatch, getState, extraParams) => {
    const newPreferences: IUserPreferences = {
      ...getState().preferences.user,
      ...preferences
    };
    return new Promise((resolve, reject) => {
      userStorage
        .setItem(USER_PREFERENCES_STORAGE_KEY, newPreferences)
        .then(() => {
          dispatch(updateUserPreferencesState(newPreferences));
          resolve();
        }, reject);
    });
  };
};
