import * as React from "react";
import Button, { IButtonProps } from "src/components/UI/Button/Button";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faCompress } from "@fortawesome/pro-regular-svg-icons/faCompress";
import { faExpandArrowsAlt } from "@fortawesome/pro-regular-svg-icons/faExpandArrowsAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Animated, AnimationType } from "src/hoc/Animated/Animated";
import "./Modal.scss";

class Modal extends React.Component<IModalProps, IModalState> {
  state: IModalState = {
    isHidden: !this.props.isOpen,
    isMinimized: false
  };

  componentDidMount() {
    document.addEventListener("keyup", this.keyupHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyupHandler);
  }

  public render() {
    const cssClasses = ["modal"];
    const isMinimized = this.state.isMinimized && this.props.isOpen;
    if (this.props.theme) {
      cssClasses.push(`modal--${this.props.theme}`);
    }
    if (this.props.className) {
      cssClasses.push(this.props.className);
    }
    if (isMinimized) {
      cssClasses.push(`modal--minimized`);
    }
    const $content =
      this.props.isOpen || !this.state.isHidden ? (
        <div className={cssClasses.join(" ")}>
          {this.props.showMinimizeBtn ? (
            <div className="modal__close-btn" onClick={this.minimize}>
              <FontAwesomeIcon icon={faCompress} />
            </div>
          ) : null}
          {this.props.showCloseBtn ? (
            <div
              className="modal__close-btn"
              onClick={
                this.props.onRequestClose
                  ? this.props.onRequestClose.bind(null)
                  : null
              }
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          ) : null}
          {this.props.title ? (
            <div className="modal__title">{this.props.title}</div>
          ) : null}
          <div className="modal__content">{this.props.children}</div>
          {this.props.buttons && this.props.buttons.length ? (
            <div className="modal__buttons">
              {this.props.buttons.map((button, idx) => {
                return (
                  <Button
                    key={idx}
                    color={button.color}
                    fill={button.fill}
                    disabled={button.disabled}
                    onClick={button.clicked}
                  >
                    {button.text}
                  </Button>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null;
    return (
      <>
        <div
          className={this.getOverlayCssClasses()}
          onClick={this.onOverlayClick}
        >
          <div className="modal__overlay-background" />
          {this.props.disableAnimation ? (
            $content
          ) : (
            <Animated
              isVisible={this.props.isOpen}
              animation={AnimationType.fadeUpDown}
              onShow={this.onContentShow}
              onHide={this.onContentHide}
              className="modal__animated-wrap"
            >
              {$content}
            </Animated>
          )}
        </div>
        {isMinimized ? (
          <div className="modal-minimized" onClick={this.expand}>
            <div className="modal-minimized__title">{this.props.title}</div>
            <div className="modal-minimized__expand-btn">
              <FontAwesomeIcon icon={faExpandArrowsAlt} />
            </div>
          </div>
        ) : null}
      </>
    );
  }

  private keyupHandler = (e: KeyboardEvent) => {
    if (
      this.props.disableOverlayClose ||
      !this.props.onRequestClose ||
      !this.props.isOpen ||
      e.key !== "Escape"
    ) {
      return;
    }
    this.props.onRequestClose();
  };

  private minimize = () => {
    this.setState({
      isMinimized: true
    });
  };

  private expand = () => {
    this.setState({
      isMinimized: false
    });
  };

  private getOverlayCssClasses() {
    const overlayCssClasses = ["modal__overlay"];
    if (this.state.isMinimized && this.props.isOpen) {
      overlayCssClasses.push(`modal__overlay--minimized`);
    }
    if (this.props.disableAnimation) {
      overlayCssClasses.push(
        this.props.isOpen ? "modal__overlay--shown" : "modal__overlay--hidden"
      );
    } else {
      if (this.props.isOpen) {
        overlayCssClasses.push(
          this.state.isHidden
            ? "modal__overlay--will-show"
            : "modal__overlay--shown"
        );
      } else {
        overlayCssClasses.push(
          this.state.isHidden
            ? "modal__overlay--hidden"
            : "modal__overlay--will-hide"
        );
      }
    }
    return overlayCssClasses.join(" ");
  }

  private onContentShow = () => {
    this.setState({ isHidden: false });
  };

  private onContentHide = () => {
    this.setState({ isHidden: true });
  };

  private onOverlayClick = (e: React.MouseEvent) => {
    if (
      this.props.disableOverlayClose ||
      !(e.target as HTMLElement).classList.contains("modal__animated-wrap")
    ) {
      return;
    }
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };
}

interface IModalState {
  isHidden: boolean;
  isMinimized: boolean;
}

interface IModalButton {
  text: string;
  color: IButtonProps["color"];
  clicked: () => void;
  fill?: IButtonProps["fill"];
  disabled?: boolean;
}

interface IModalProps {
  isOpen: boolean;
  isMinimized?: boolean;
  title?: string;
  buttons?: IModalButton[];
  onRequestClose?: () => void;
  theme?: "default" | "white";
  showCloseBtn?: boolean;
  showMinimizeBtn?: boolean;
  disableOverlayClose?: boolean;
  className?: string;
  disableAnimation?: boolean;
}

export default Modal;
