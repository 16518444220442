import * as React from "react";

export class OnboardingStep {
  constructor(readonly id: OnboardingStepId) {}

  get nextButtonDisabled(): boolean {
    return [
      OnboardingStepId.callAnswer,
      OnboardingStepId.callHold,
      OnboardingStepId.callUnhold,
      OnboardingStepId.callTransfer,
      OnboardingStepId.transferAttended,
      OnboardingStepId.callCancelAttendedTransfer,
      OnboardingStepId.navigateQueues
    ].includes(this.id);
  }

  get hasResetButton(): boolean {
    return OnboardingStepId.finish === this.id;
  }

  get hasPrevButton(): boolean {
    return ![OnboardingStepId.welcome, OnboardingStepId.welcomeGuest].includes(
      this.id
    );
  }

  get message(): React.ReactNode {
    switch (this.id) {
      case OnboardingStepId.welcome:
        return (
          <div>
            <h2>Welcome to Compass Bridge</h2>
            <br />
            Welcome to your home screen! Let’s start our quick tour to get you
            familiar with the application.
          </div>
        );
      case OnboardingStepId.welcomeGuest:
        return (
          <div>
            <h2>Welcome to Compass Bridge</h2>
            <br />
            This is how your home screen looks like. Let’s start our quick tour
            to get you familiar with the application.
          </div>
        );
      case OnboardingStepId.sectionCalls:
        return (
          <span>
            This is where all your calls are located. Click “Next” to receive an
            automated call for demo purpose.
          </span>
        );
      case OnboardingStepId.callAnswer:
        return (
          <span>
            You are now receiving a demo call. To answer the call, click the
            answer button.
          </span>
        );
      case OnboardingStepId.callHold:
        return (
          <span>
            Good job! The card has turned blue because it’s an active call.
            Press the pause button to put the call on hold.
          </span>
        );
      case OnboardingStepId.callUnhold:
        return (
          <span>
            The dark background signifies the button is active; which means the
            call is on hold in this instance.
            <br />
            <br />
            To take the call off hold, press the same button again.
          </span>
        );
      case OnboardingStepId.callTransfer:
        return (
          <span>
            Good! Let’s transfer this call to someone from your contact list.
            Click the arrow button to start a transfer.
          </span>
        );
      case OnboardingStepId.modalTransfer:
        return (
          <span>
            The transfer button opens this transfer funnel where you can select
            to whom you are transferring the call; and whether it’s an attended
            or unattended transfer.
          </span>
        );
      case OnboardingStepId.transferAttended:
        return <span>Let's select attended transfer.</span>;
      case OnboardingStepId.callCancelAttendedTransfer:
        return (
          <span>
            Great!
            <br />
            <br />
            This is how transfer modal looks like. Remember, you can minimize it
            if you need to quickly go back to other calls, but you can't
            completely make it go away until you resolve the transfer.
            <br />
            <br />
            Let's cancel the transfer.
          </span>
        );
      case OnboardingStepId.navigateQueues:
        return (
          <span>
            Good job! Now that you’ve seen how to manage calls and transfers,
            let’s take a quick look at queues.
            <br />
            <br />
            Click here to view a list of queues.
          </span>
        );
      case OnboardingStepId.sectionQueues:
        return (
          <span>
            This is where all your queues are located. By clicking on one of
            them, you can see the corresponding details on the right side, such
            as queue statistics, a list of people that are waiting and which
            agents are in it. Also, you can easily join and leave the queues
            from here.
            <br />
            <br />
            You can come back to investigate queues a bit later. Now let's have
            a look at the menu.
          </span>
        );
      case OnboardingStepId.sectionNavbarDropdown:
        return (
          <span>
            In this menu, you can find additional settings and information.
            Let's look at some of them.
          </span>
        );
      case OnboardingStepId.navbarView:
        return (
          <span>
            Agent/Reception view enables you to toggle between having contacts
            or queues as your main focus.
          </span>
        );
      case OnboardingStepId.navbarCompact:
        return (
          <span>
            Compact view removes excessive white space so that you fit more
            information on the screen.
          </span>
        );
      case OnboardingStepId.navbarHelp:
        return (
          <span>
            Help button opens explanations of icons and buttons used throughout
            this application.
          </span>
        );
      case OnboardingStepId.navbarSendFeedback:
        return (
          <span>
            Feedback button opens a form which you can use to send us comments
            and ideas to improve Bridge.
          </span>
        );
      case OnboardingStepId.finish:
        return (
          <div>
            <h2>That's it!</h2>
            <br />
            That’s it, you’ve mastered the basics of Compass Bridge!
          </div>
        );
      case OnboardingStepId.finishNoPremium:
        return (
          <div>
            <h2>That's it!</h2>
            <br />
            That’s it, you’ve mastered the basics of Compass Bridge! The basic
            features are free. To be able to use all features, contact the
            company administrator to upgrade your account to Compass Bridge
            Premium.
          </div>
        );
      case OnboardingStepId.endNoPremium:
        return (
          <div>
            <h2>You don't have Premium.</h2>
            <br />
            Don't worry, basic features are free. To be able to use all
            features, contact the company administrator to upgrade your account
            to Compass Bridge Premium.
          </div>
        );
      case OnboardingStepId.finishGuest:
        return (
          <div>
            <h2>That's it</h2>
            <br />
            We hope you enjoyed the tutorial and we'd love to hear from you if
            you're interested in using the application in your company.
          </div>
        );
    }
    return "";
  }

  get isLastStep(): boolean {
    return [
      OnboardingStepId.finish,
      OnboardingStepId.finishNoPremium,
      OnboardingStepId.endNoPremium
    ].includes(this.id);
  }

  get nextBtnString(): string {
    if (this.isLastStep) {
      return "Start using Bridge";
    }
    return "Next";
  }
}

export enum OnboardingStepId {
  welcome = "welcome",
  welcomeGuest = "welcomeGuest",
  sectionCalls = "sectionCalls",
  callAnswer = "callAnswer",
  callHold = "callHold",
  callUnhold = "callUnhold",
  callTransfer = "callTransfer",
  modalTransfer = "modalTransfer",
  transferAttended = "transferAttended",
  callCancelAttendedTransfer = "callCancelAttendedTransfer",
  navigateQueues = "navigateQueues",
  sectionQueues = "sectionQueues",
  sectionNavbarDropdown = "sectionNavbarDropdown",
  navbarView = "navbarView",
  navbarCompact = "navbarCompact",
  navbarHelp = "navbarHelp",
  navbarSendFeedback = "navbarSendFeedback",
  finish = "finish",
  finishGuest = "finishGuest",
  finishNoPremium = "finishNoPremium",
  endNoPremium = "endNoPremium"
}
