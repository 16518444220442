import * as React from "react";
import "./GlobalAlert.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export interface IGlobalAlertProps {
  message: string;
  level: "success" | "info" | "danger";
  icon?: IconDefinition;
}

export const GlobalAlert: React.FC<IGlobalAlertProps> = props => {
  return (
    <div className={`global-alert global-alert--${props.level}`}>
      {props.icon ? (
        <div className="global-alert__icon">
          <FontAwesomeIcon icon={props.icon} />
        </div>
      ) : null}
      <div className="global-alert__message">{props.message}</div>
    </div>
  );
};
