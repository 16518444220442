import { store } from "src/store";

export enum TrackCategory {
  callItem = "call-card",
  navbar = "navbar",
  dialer = "dialer",
  contactItem = "contact-item",
  contactDetails = "contact-details",
  contactsList = "contacts-list",
  contactsOverview = "contacts-overview",
  queueItem = "queue-item",
  queueDetails = "queue-details",
  queueList = "queue-list",
  calls = "calls",
  contacts = "contacts",
  transferModal = "transfer-modal"
}

export enum TrackAction {
  callItemAnswer = "call-card-answer",
  callItemHold = "call-card-hold",
  callItemUnhold = "call-card-unhold",
  callItemHangup = "call-card-hangup",
  callItemTransfer = "call-card-transfer",
  callItemDtmf = "call-card-dtmf",
  callItemMergeAttendedTransfer = "call-card-merge-attended-transfer",

  navbarOpenFullScreenContacts = "navbar-open-full-screen-contacts",
  navbarToggleDefaultHomeList = "navbar-toggle-default-home-list",
  navbarToggleViewMode = "navbar-toggle-view-mode",
  navbarHelp = "navbar-help",
  navbarSyncContacts = "navbar-sync-contacts",
  navbarLogout = "navbar-logout",
  navbarSwitchPhone = "navbar-switch-phone",
  navbarLoginPhone = "navbar-login-phone",
  navbarLogoutPhone = "navbar-logout-phone",
  navbarOpenTutorial = "navbar-open-tutorial",
  navbarFeedback = "navbar-feedback",
  navbarPrivacyPolicy = "navbar-privacy-policy",
  navbarManual = "navbar-manual",
  navbarPremium = "navbar-premium",

  navbarStatusAvailable = "navbar-status-available",
  navbarStatusAway = "navbar-status-away",
  navbarStatusBusy = "navbar-status-busy",

  dialerDial = "dialer-dial",
  dialerTransfer = "dialer-transfer",
  dialerAttendedTransfer = "dialer-attended-transfer",

  contactItemPinToggle = "contact-item-pin-toggle",
  contactItemContextPinToggle = "contact-item-context-pin-toggle",
  contactItemContextDial = "contact-item-context-dial",

  contactDetailsDial = "contact-details-dial",
  contactDetailsEmail = "contact-details-email",

  contactsListOpenDetails = "contacts-list-open-details",
  contactsListOpenQueues = "contacts-list-open-queues",
  contactsListOpenDialPad = "contacts-list-open-dial-pad",
  contactsListGoBack = "contacts-list-go-back",

  contactsOverviewLetterClick = "contacts-overview-letter-click",
  contactsOverviewDialContact = "contacts-overview-dial-contact",

  queueItemPinToggle = "queue-item-pin-toggle",
  queueItemContextPinToggle = "queue-item-context-pin-toggle",
  queueItemContextLogin = "queue-item-context-login",
  queueItemContextLogout = "queue-item-context-logout",

  queueDetailsLogin = "queue-details-login",
  queueDetailsLogout = "queue-details-logout",
  queueDetailsPin = "queue-details-pin",
  queueDetailsUnpin = "queue-details-unpin",
  queueDetailsOpenManageModal = "queue-details-open-manage-modal",
  queueDetailsShowDetails = "queue-details-show-details",
  queueDetailsShowWaiting = "queue-details-show-waiting",
  queueDetailsShowAgents = "queue-details-show-agents",
  queueDetailsShowCallers = "queue-details-show-callers",

  queueListEnableGlobalPause = "queue-list-enable-global-pause",
  queueListDisableGlobalPause = "queue-list-disable-global-pause",
  queueListOpenContacts = "queue-list-open-contacts",
  queueListGoBack = "queue-list-go-back",

  callsIncoming = "calls-incoming",
  callsOutgoing = "calls-outgoing",
  callsNumber = "calls-number",

  contactsNumber = "contacts-number",

  transferModalClose = "transfer-modal-close",
  transferModalDialerShow = "transfer-modal-dialer-show",
  transferModalDialerHide = "transfer-modal-dialer-hide",
  transferModalDirectTransfer = "transfer-modal-direct-transfer",
  transferModalAttendedTransfer = "transfer-modal-attended-transfer",
  transferModalCancelAttendedTransfer = "transfer-modal-cancel-attended-transfer",
  transferModalFinishAttendedTransfer = "transfer-modal-finish-attended-transfer"
}

const notInitializedWarn = () => {
  console.error("WARNING: Matomo tracker not initialized");
};

const isOnboarding = () => store.getState().auth.onboardingMode;

export const trackPageView = (page: string) => {
  if (isOnboarding()) {
    return;
  }
  if (!window._paq) {
    return notInitializedWarn();
  }
  window._paq.push(["setCustomUrl", `/${page}`]);
  window._paq.push(["trackPageView"]);
};

export const trackEvent = (
  category: TrackCategory,
  action: TrackAction,
  name?: string,
  value?: string
) => {
  if (isOnboarding()) {
    return;
  }
  if (!window._paq) {
    return notInitializedWarn();
  }
  window._paq.push(["trackEvent", category, action, name, value]);
};

export const setUserId = (userId: string) => {
  if (isOnboarding()) {
    return;
  }
  if (!window._paq) {
    return notInitializedWarn();
  }
  window._paq.push(["setUserId", userId]);
};

export const initTracking = () => {
  window._paq = window._paq || [];
  (() => {
    const u = "//analytics.iperity.com/";
    window._paq.push(["setTrackerUrl", u + "piwik.php"]);
    window._paq.push(["setSiteId", "2"]);
    window._paq.push(["enableHeartBeatTimer"]);
    const g = document.createElement("script");
    const s = document.getElementsByTagName("script")[0] as HTMLScriptElement;
    g.type = "text/javascript";
    g.async = true;
    g.defer = true;
    g.src = u + "piwik.js";
    if (s && s.parentNode) {
      s.parentNode.insertBefore(g, s);
    }
  })();
};
